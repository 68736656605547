import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CashbackBagIcon = ({ size = 8, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 28 30' color={color}>
      <path
        d='M8.38024 30C7.03447 30 4.12618 29.746 2.09944 27.784C0.532072 26.2668 -0.163458 24.0864 0.0322608 21.3026C0.561187 13.7877 6.80964 9.2748 9.10489 7.8611L5.82295 2.61389C5.60459 2.26612 5.65635 1.81322 5.9475 1.52207C7.13799 0.333195 8.73286 -0.176322 10.5606 0.0533651C12.2882 0.268494 14.0399 0.268494 15.7658 0.0533651C17.5936 -0.176322 19.1885 0.331577 20.3773 1.52207C20.6685 1.81322 20.7202 2.26612 20.5019 2.61389L17.2216 7.8611C19.5184 9.2748 25.7653 13.7877 26.2942 21.3026C26.4899 24.0864 25.7944 26.2668 24.227 27.784C21.5355 30.3882 17.2911 29.9822 17.1148 29.9644L13.1697 29.9968L9.11783 29.9693C9.0968 29.9725 8.82344 30 8.38186 30H8.38024ZM7.72838 2.33244L11.0863 7.70096C11.2174 7.90962 11.2546 8.16357 11.1899 8.40135C11.1252 8.63912 10.9634 8.83807 10.745 8.95292C10.6609 8.99659 2.35824 13.4027 1.79373 21.4272C1.63683 23.6674 2.15282 25.3788 3.32875 26.5175C5.43637 28.5572 8.99651 28.2159 9.03372 28.211L13.1713 28.2337L17.2005 28.2062C17.3299 28.2159 20.9143 28.5442 23.0074 26.511C24.1785 25.3739 24.6929 23.6626 24.536 21.4272C23.9714 13.4027 15.6688 8.99659 15.5846 8.95292C15.3663 8.83969 15.2045 8.63912 15.1398 8.40135C15.0751 8.16357 15.1123 7.90962 15.2433 7.70096L18.5997 2.33244C17.8815 1.85527 17.0048 1.68058 15.9874 1.80513C14.1143 2.03805 12.217 2.03805 10.3455 1.80513C9.3281 1.68058 8.45141 1.85527 7.73324 2.33244H7.72838Z'
        fill='currentColor'
      />
      <path
        d='M15.9876 9.04995H10.3376C9.85073 9.04995 9.45605 8.65528 9.45605 8.16841C9.45605 7.68154 9.85073 7.28687 10.3376 7.28687H15.9876C16.4744 7.28687 16.8691 7.68154 16.8691 8.16841C16.8691 8.65528 16.4744 9.04995 15.9876 9.04995Z'
        fill='currentColor'
      />
      <path
        d='M13.6371 24.5166H12.6876C11.0895 24.5166 9.79068 23.2162 9.79068 21.6197C9.79068 21.1328 10.1854 20.7381 10.6722 20.7381C11.1591 20.7381 11.5538 21.1328 11.5538 21.6197C11.5538 22.244 12.0617 22.7519 12.686 22.7519H13.6355C14.2599 22.7519 14.7678 22.244 14.7678 21.6197C14.7678 20.9953 14.2599 20.4874 13.6355 20.4874H12.686C11.0879 20.4874 9.78906 19.1869 9.78906 17.5904C9.78906 15.994 11.0895 14.6935 12.686 14.6935H13.6355C15.2336 14.6935 16.5325 15.994 16.5325 17.5904C16.5325 18.0773 16.1378 18.472 15.6509 18.472C15.1641 18.472 14.7694 18.0773 14.7694 17.5904C14.7694 16.9661 14.2615 16.4582 13.6371 16.4582H12.6876C12.0633 16.4582 11.5554 16.9661 11.5554 17.5904C11.5554 18.2148 12.0633 18.7227 12.6876 18.7227H13.6371C15.2352 18.7227 16.5341 20.0216 16.5341 21.6197C16.5341 23.2178 15.2336 24.5166 13.6371 24.5166Z'
        fill='currentColor'
      />
      <path
        d='M13.1628 25.4645C12.6759 25.4645 12.2812 25.0698 12.2812 24.5829V23.6351C12.2812 23.1482 12.6759 22.7535 13.1628 22.7535C13.6497 22.7535 14.0443 23.1482 14.0443 23.6351V24.5829C14.0443 25.0698 13.6497 25.4645 13.1628 25.4645Z'
        fill='currentColor'
      />
      <path
        d='M13.1628 16.4582C12.6759 16.4582 12.2812 16.0635 12.2812 15.5767V14.6288C12.2812 14.1419 12.6759 13.7473 13.1628 13.7473C13.6497 13.7473 14.0443 14.1419 14.0443 14.6288V15.5767C14.0443 16.0635 13.6497 16.4582 13.1628 16.4582Z'
        fill='currentColor'
      />
    </Icon>
  );
};

CashbackBagIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
