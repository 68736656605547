import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as Yup from 'yup';
import { signUp } from '~/api';
import useGlobalForest from '~/hooks/useGlobalForest';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { handleError } from '~/lib/errors';
import { goUrl, neutralizeEvent } from '~/lib/helpers';
import { logEvent } from '~/lib/logEvent';
import { message, TOAST_STATUS } from '~/lib/message';
import globalState from '~/lib/state/global-state';
import { ErrorRow } from '../common/error-row';

const watchedProperties = ['sign_in_url'];

export function SignUpModal({ isOpen, onClose, openSignIn }) {
  const { sign_in_url } = useGlobalForest(globalState, watchedProperties);
  const { genericTranslation } = useTranslations();
  const { emailValidation, passwordValidation, confirmPasswordValidation } = useValidationSchema();

  const onSubmit = async ({ email, password }, { resetForm }) => {
    try {
      await signUp(email, password);

      message({
        title: genericTranslation.signUp.successTitle,
        description: genericTranslation.signUp.successDescription,
        status: TOAST_STATUS.SUCCESS,
        duration: 4000,
        isClosable: true,
      });

      logEvent('login', { action: 'sign_up' });

      onClose();
      resetForm();
    } catch (submitErr) {
      console.error('Sign up error', submitErr);
      handleError(submitErr, 'Cannot sign up');
      resetForm();
    }
  };

  const [showPassword, setShowPassword] = useBoolean(false);
  const [showConfirmPassword, setShowConfirmPassword] = useBoolean(false);

  const validationSchema = Yup.object().shape({
    email: emailValidation,
    password: passwordValidation.test(
      'password-is-not-username',
      genericTranslation.validation.passwordIsNotUsername,
      function (value) {
        return value && value.toLowerCase() !== this.parent.email.toLowerCase();
      }
    ),
    confirmPassword: confirmPasswordValidation('password'),
  });

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  const { handleSubmit, handleReset, values, handleBlur, handleChange, errors } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const goToSignIn = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_in_url) {
        goUrl(sign_in_url);
      } else {
        onClose();
        openSignIn();
      }
    },
    [openSignIn, onClose, sign_in_url]
  );

  const handleClose = useCallback(() => {
    onClose();
    handleReset();
  }, [handleReset, onClose]);

  const isDisabled = Object.keys(errors).length > 0;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered blockScrollOnMount>
      <ModalOverlay />
      <ModalContent as='form' onSubmit={handleSubmit}>
        <ModalHeader mb={0}>{genericTranslation.getStarted}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Text mb={4} color='secondary.primary' textAlign='center'>
            {genericTranslation.signUp.freeForever}
          </Text>
          <Stack direction='column' spacing={4}>
            <VStack>
              <FormControl>
                <FormLabel>{genericTranslation.email}</FormLabel>
                <Input
                  name='email'
                  layerStyle='sign-in-field'
                  value={values.email}
                  placeholder={genericTranslation.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <ErrorRow isPresent={Boolean(errors['email'])}>{errors['email']}</ErrorRow>
            </VStack>

            <VStack>
              <FormControl>
                <Box w='100%'>
                  <HStack w='100%' justify='space-between' mb={2}>
                    <FormLabel mb={0}>{genericTranslation.password}</FormLabel>
                  </HStack>
                </Box>
                <InputGroup>
                  <Input
                    name='password'
                    layerStyle='sign-in-field'
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    placeholder={genericTranslation.password}
                    data-testid='password-field'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputRightElement height='100%'>
                    <Button
                      onClick={setShowPassword.toggle}
                      color='blackAlpha.500'
                      variant='unstyled'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      mr={1}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <ErrorRow isPresent={Boolean(errors['password'])}>{errors['password']}</ErrorRow>

              <FormControl mt={2}>
                <FormLabel>{genericTranslation.confirmPassword}</FormLabel>
                <InputGroup>
                  <Input
                    name='confirmPassword'
                    layerStyle='sign-in-field'
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    placeholder={genericTranslation.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputRightElement height='100%'>
                    <Button
                      onClick={setShowConfirmPassword.toggle}
                      color='blackAlpha.500'
                      variant='unstyled'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      mr={1}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <ErrorRow isPresent={Boolean(errors['confirmPassword'])}>
                {errors['confirmPassword']}
              </ErrorRow>
            </VStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button width='100%' isDisabled={isDisabled} data-testid='sign-up-button' type='submit'>
            {genericTranslation.signUp.cta}
          </Button>
        </ModalFooter>
        <Text textAlign='center'>
          {`${genericTranslation.signUp.alreadyRegistered} `}
          <Button
            variant='link'
            mt={2}
            display='inline-block'
            fontWeight='normal'
            onClick={goToSignIn}
          >
            {genericTranslation.signIn.cta}
          </Button>
        </Text>
      </ModalContent>
    </Modal>
  );
}

SignUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openSignIn: PropTypes.func.isRequired,
};
