import { BadStatusError } from '/src/lib/errors/index.js';
import { responseStatusIsGood } from '/src/lib/helpers.js';
import { USER_URL } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';

export const signUp = async (email, password) => {
  if (!email || !password) {
    throw new Error('Email and password are required');
  }

  const awsWafApi = window?.AwsWafIntegration?.fetch || fetch;

  try {
    const response = await awsWafApi(`${USER_URL}signup`, {
      body: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!responseStatusIsGood(response)) {
      throw new BadStatusError(response);
    }

    return await response.json();
  } catch (error) {
    console.error('Sign up error', error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  if (!email || !password) {
    throw new Error('Email and password are required');
  }

  try {
    const response = await fetchUrl(
      `${USER_URL}login`,
      {
        accountState,
        body: JSON.stringify({ email, password }),
        method: 'POST',
      },
      { noAccessToken: true }
    );

    return await response.json();
  } catch (error) {
    console.error('Sign in error', error);
    throw error;
  }
};
