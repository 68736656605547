'use client';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
//👇 Import Open Sans font
import useGlobalForest from '~/hooks/useGlobalForest';
import globalState from '~/lib/state/global-state';
import { defaultTheme, findKeepLoveTheme } from '~/theme';

const FIND_KEEP_LOVE = 'FindKeepLove';

const watchedProperties = ['themeExtension', 'isConfigLoaded', 'publisher_name'];

export default function Chakra({ children }) {
  const { themeExtension, isConfigLoaded, publisher_name } = useGlobalForest(globalState, watchedProperties);

  let publisherTheme;

  switch (publisher_name) {
    case FIND_KEEP_LOVE:
      publisherTheme = findKeepLoveTheme;
      break;
    default:
      publisherTheme = defaultTheme;
      break;
  }

  return (
    <>
      <ColorModeScript initialColorMode={publisherTheme.config.initialColorMode} />
      <ChakraProvider
        theme={isConfigLoaded ? extendTheme(publisherTheme, themeExtension) : publisherTheme}
      >
        {children}
      </ChakraProvider>
    </>
  );
}

Chakra.propTypes = {
  children: PropTypes.node,
};
