import { computeThemeUpdate } from '~/hooks/helpers';
import fetchUrl from '~/lib/fetchUrl';
import { currentTimeInMS, goUrl, neutralizeEvent, responseStatusIsGood } from '~/lib/helpers';
import { message } from '~/lib/message';
import accountStateFactory from '~/lib/state/accountStateFactory';
import globalState from '~/lib/state/global-state';

// @TODO: date-stamp and auto-invalidate stale access tokens

/**
 * this is the global "account status" state
 * to share whether you are logged in across the site.
 *
 * It also has a child state for the user data,
 * used in login/signup forms
 */

const localStorage = typeof window === 'undefined' ? null : window.localStorage;

const accountState = accountStateFactory(
  {
    currentTimeInMS,
    computeThemeUpdate,
    goUrl,
    message,
    fetchUrl: fetchUrl,
    neutralizeEvent,
    responseStatusIsGood,
  },
  localStorage,
  globalState
);

accountState.do.loadSession();

export default accountState;
