import { useEffect, useState } from 'react';
import useGlobalForest from './hooks/useGlobalForest';
import globalState from './lib/state/global-state';
import { Router } from './pages/router';

const watchedProperties = ['isConfigLoaded'];

/*
  App initializes key pieces of state and loads the white label's config in careful, specific order
  1. initialize globalState
  2. initialize accountState
  3. fetch white label site config
  4. parse config with action inside globalState
  5. render Router

  The purpose of this component is pretty singular so think hard before adding
  anything else to this file.
*/
export const App = () => {
  const gState = useGlobalForest(globalState, watchedProperties);
  const [displayRouter, setDisplayRouter] = useState(false);

  useEffect(() => {
    const lazyLoad = async () => {
      await import('~/lib/state/account-state');

      const { fetchConfig } = await import('~/lib/fetch-config');

      const config = await fetchConfig();

      globalState.do.parseConfig(config);

      setDisplayRouter(true);
    };

    if (Object.keys(gState ?? {}).length === 1) {
      lazyLoad();
    }
  }, [gState]);

  return displayRouter ? <Router /> : null;
};
