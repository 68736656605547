/* @global Plaid */

import { Forest } from '@wonderlandlabs/forest';
import { BAD_BODY, BRANDS_ROUTE, ONE_TIME_TOKEN_URL, USER_URL } from '~/lib/constants';
import { handleError, SilentError } from '~/lib/errors';
import globalStateFactory from '~/lib/state/global-state-factory';
import { CustomError } from '../errors';
import { TOAST_STATUS } from '../message';
export default function accountStateFactory(utilities, localStorage, globalState) {
  const { currentTimeInMS, goUrl, message, fetchUrl } = utilities;
  if (!globalState) {
    globalState = new Forest(globalStateFactory(utilities));
  }
  const accountState = new Forest({
    $value: {
      user_email: '',
      access_token: '',
      access_token_timeout: 0,
      change_password_access_token: '',
      refresh_token: null,
      link_token: '',
      isLoggedIn: false,
      error: null,
      oneTimeToken: null,
      isAccountStateReady: false,
    },
    actions: {
      /**
       * returns an object of headr key/value pairs.
       * Because it loads publisher_id from config,
       * it must await for the config to finish loading.
       */

      loadSession(state) {
        if (!localStorage) {
          return;
        }
        let access_token = null;
        let access_token_timeout = 0;
        let change_password_access_token = null;
        let isLoggedIn = false;
        let link_token = '';
        let refresh_token = null;
        let user_email = '';
        let isAccountStateReady = true;

        access_token = localStorage.getItem('access_token') ?? null;
        if (access_token) {
          isLoggedIn = true;
          user_email = localStorage.getItem('user_email') ?? '';
          refresh_token = localStorage.getItem('refresh_token') ?? null;
          change_password_access_token = localStorage.getItem('change_password_access_token');
          access_token_timeout = Number(localStorage.getItem('access_token_timeout') || 0);
          link_token = localStorage.getItem('link_token') || '';
        }

        state.do.set_user_email(user_email);
        state.do.set_access_token(access_token);
        state.do.set_access_token_timeout(access_token_timeout);
        state.do.set_change_password_access_token(change_password_access_token);
        state.do.set_isLoggedIn(isLoggedIn);
        state.do.set_link_token(link_token);
        state.do.set_refresh_token(refresh_token);
        state.do.set_isAccountStateReady(isAccountStateReady);
      },

      async checkExpiration(state, navigate) {
        const { refresh_token } = state.value;
        if (state.$.isExpired()) {
          try {
            if (refresh_token) {
              await state.do.refreshAuth(navigate);
            } else {
              console.warn('Signing out user -- no refresh token', state.value);
              state.do.signOut(navigate);
            }
          } catch (err) {
            console.warn('Signing out user -- attempt to auto-refresh token failed: ', err.message);
            state.do.signOut(navigate, 'Login has timed out');
          }
        }
      },
      prepareSignUpBody(state) {
        const userForm = state.child('userForm');
        try {
          return userForm.$.signUpBody();
        } catch (bodyErr) {
          message({
            title: 'error forming request',
            description: bodyErr.message,
            duration: 200,
            status: TOAST_STATUS.ERROR,
          });
          return BAD_BODY;
        }
      },
      readLoginData(state, data, email) {
        let {
          access_token,
          refresh_token,
          change_password_access_token,
          access_token_timeout,
          expires_in,
        } = data;
        if (!access_token_timeout) {
          access_token_timeout = 1000 * (Number(expires_in) ?? 60 * 5) + currentTimeInMS();
        } else {
          access_token_timeout = Number(access_token_timeout);
        }
        state.do.set_access_token_timeout(access_token_timeout);
        state.do.set_access_token(access_token);
        state.do.set_change_password_access_token(change_password_access_token);
        state.do.set_refresh_token(refresh_token);
        if (email) {
          state.do.set_user_email(email);
        }
        state.do.serializeLoggedInData();
      },
      async refreshAuth(state, navigate) {
        const { refresh_token, access_token } = state.value;
        try {
          // native fetch, not fetchUrl with our wrapper around it
          const response = await fetch(`${USER_URL}refresh-token`, {
            body: JSON.stringify({ refresh_token }),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          });

          const data = await response.json();

          const { access_token: new_access_token, expires_in, change_password_access_token } = data;
          if (!new_access_token) {
            throw new CustomError({
              title: 'Cannot refresh auth token',
              description: 'Access token not found in response',
            });
          }
          state.do.set_access_token(new_access_token);
          state.do.set_access_token_timeout((expires_in || 5 * 60) * 1000 + currentTimeInMS());
          state.do.set_change_password_access_token(change_password_access_token);
          state.do.serializeLoggedInData();
        } catch (err) {
          handleError(err, 'Login has timed out');

          state.do.signOut(navigate, 'Login has timed out');

          // Show user "Login has timed out" toast, and suppress the request-specific error toasts.
          throw new SilentError();
        }
      },
      readToken(state, navigate) {
        if (typeof window === 'undefined') {
          return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('token');
        if (!token) {
          return;
        }
        // Tokens are base64 encoded, which may contain '+', and URLSearchParams will read it as space ' ',
        // Need to replace ' ' with '+' to obtain the correct token value
        // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
        token = token.replace(/ /g, '+');
        const url = new URL(window.location.href);
        window.history.replaceState({}, '', url.origin + BRANDS_ROUTE);
        if (navigate) {
          navigate(BRANDS_ROUTE);
        }
        state.do.fetchAccessToken(token);
      },
      async fetchAccessToken(state, token) {
        state.do.set_oneTimeToken(token);

        try {
          const response = await fetchUrl(
            ONE_TIME_TOKEN_URL,
            {
              accountState,
              method: 'POST',
              body: JSON.stringify({ token }),
            },
            { noAccessToken: true }
          );

          const data = await response.json();
          const { access_token, email } = data;
          if (access_token) {
            state.do.readLoginData(data);
            state.do.set_isLoggedIn(true);
            state.do.set_user_email(email);
          } else {
            throw new CustomError({
              title: 'Cannot get access token',
              description: `Access token was not found in response`,
            });
          }
        } catch (err) {
          handleError(err, 'Cannot get access token');
        }
      },
      serializeLoggedInData(state) {
        if (!localStorage) {
          return;
        }
        const {
          access_token,
          user_email,
          refresh_token,
          access_token_timeout,
          change_password_access_token,
          link_token,
        } = state.value;
        localStorage.setItem('user_email', user_email ?? '');
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('access_token_timeout', access_token_timeout);
        localStorage.setItem('change_password_access_token', change_password_access_token);
        localStorage.setItem('link_token', link_token);
      },
      signOut(state, navigate, customErrorMessage) {
        state.do.set_isLoggedIn(false);
        state.do.set_user_email('');
        state.do.set_access_token(null);
        state.do.set_change_password_access_token(null);

        message({
          title: 'Logged out',
          description: customErrorMessage || 'You have been logged out',
          duration: 3000,
          status: TOAST_STATUS.INFO,
        });

        if (localStorage) {
          localStorage.clear();
        }

        const { sign_out_url } = globalState.value;

        if (!navigate) {
          return;
        }

        if (sign_out_url) {
          goUrl(sign_out_url);
        } else {
          (navigate ?? goUrl)(BRANDS_ROUTE);
        }
      },
    },
    meta: {
      globalState,
    },
    name: 'account',
    selectors: {
      accessTokenSummary(state) {
        const { access_token } = state.value;
        if (!access_token) {
          return '(absent)';
        }
        return `${access_token.substring(0, 5)}...${access_token.substring(-5)}`;
      },
      userEmail(state) {
        return state.value.user?.email ?? '--';
      },
      isExpired(state, threshold = 0) {
        const { isLoggedIn, access_token_timeout } = state.value;
        return (
          isLoggedIn &&
          (!access_token_timeout || access_token_timeout - threshold < currentTimeInMS())
        );
      },

      /**
       * note - because it uses publisher_id from config, it should not be called directly
       * as the publisher_id may not have been loaded.
       */

      timeTilTokenExpires(state, inSecs) {
        const { isLoggedIn, access_token_timeout } = state.value;
        if (!(isLoggedIn && access_token_timeout)) {
          return 0;
        }
        const ms = access_token_timeout - currentTimeInMS();
        // console.info('access_token_timeout', access_token_timeout, 'ms', ms);
        return inSecs ? Math.floor(ms / 1000) : ms;
      },
    },
  });
  return accountState;
}
