import { Forest } from '@wonderlandlabs/forest';
import { computeThemeUpdate } from '~/hooks/helpers';
import { message } from '~/lib/message';
import globalStateFactory from '~/lib/state/global-state-factory';

/**
 * This is a "general purpose shared state"
 */
const globalState = new Forest(
  globalStateFactory({
    computeThemeUpdate,
    message,
    window,
  })
);

export default globalState;
