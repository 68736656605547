import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useGlobalForest from '~/hooks/useGlobalForest';
import { useTranslations } from '~/hooks/useTranslations';
import { ACCOUNT_ROUTE, REGIONS } from '~/lib/constants';
import imgUrl, { res } from '~/lib/helpers';
import globalState from '~/lib/state/global-state';
import { GettingStartedColumn } from '~/pages/landing/helpers';

function GettingStartedButton() {
  return (
    <Link to={ACCOUNT_ROUTE}>
      <Box layerStyle='landing-getting-started-button'>
        <Center>
          <Button variant='landingPageGettingStarted'>Get Started</Button>
        </Center>
      </Box>
    </Link>
  );
}


const StatsTableStats = memo(function STS({ big, small, area }) {
  return (
    <GridItem area={area} layerStyle='landing-stat'>
      <VStack alignItems='center' gap={0}>
        <Text as='p' textStyle='landing-stat-big'>
          {big}
        </Text>
        <Text as='p'>{small}</Text>
      </VStack>
    </GridItem>
  );
});

StatsTableStats.propTypes = {
  area: PropTypes.string,
  big: PropTypes.string,
  small: PropTypes.string,
};



function PlugTable({ landingTranslation }) {
  return (
    <Center>
      <Grid
        gridTemplateAreas={res({
          base: '"img1" "txt1" "img2" "txt2"',
          md: '"img1 txt1" "txt2 img2"',
        })}
        gridTemplateColumns={res({ base: '1fr', md: '1fr 1fr' })}
        gridTemplateRows={res({ base: '1fr auto 1fr auto', md: '1fr 1fr' })}
        gridColumnGap={res({ base: 20, lg: 80 }, true)}
        gridRowGap={res({ base: 20, lg: 200 }, true)}
        mt={res({ base: 10, lg: 20 })}
        layerStyle='landing-plug'
      >
        <GridItem
          area='img1'
          data-role='img1'
          maxWidth='700px'
          layerStyle='landing-plug-image'
          backgroundImage={imgUrl('/img/lp/cashback.jpg')}
        ></GridItem>

        <GridItem area='txt1' layerStyle='landing-plug-text'>
          <VStack justifyContent='center' height='100%' alignContent='left' alignItems='left'>
            <Heading variant='landing-plug'>{landingTranslation.plug_cashback_text.title}</Heading>
            <Text textStyle='landing-plug'>
              {landingTranslation.plug_cashback_text.description}
            </Text>
          </VStack>
        </GridItem>

        <GridItem
          area='img2'
          data-role='img2'
          maxWidth='700px'
          layerStyle='landing-plug-image'
          backgroundImage={imgUrl('/img/lp/shutterstock_1931167616.jpg')}
        ></GridItem>

        <GridItem area='txt2' layerStyle='landing-plug-text'>
          <VStack justifyContent='center' height='100%' alignContent='left' alignItems='left'>
            <Heading variant='landing-plug'>
              {landingTranslation.plug_link_cards_text.title}
            </Heading>
            <Text textStyle='landing-plug'>
              {landingTranslation.plug_link_cards_text.description}
            </Text>
          </VStack>
        </GridItem>
      </Grid>
    </Center>
  );
}

PlugTable.propTypes = {
  landingTranslation: PropTypes.object,
};

function Questions({ questionsAndAnswers, landingTranslation }) {
  useEffect(() => {
    const t = setTimeout(() => {
      if (typeof window === 'undefined') {
        return;
      }
      const url = new URL(window.location.href);
      if (url.hash === '#faq') {
        window.document.getElementById('faq')?.scrollIntoView();
      }
    }, 500);
    return () => clearTimeout(t);
  }, []);
  return (
    <Box layerStyle='landing-questions' id='faq'>
      <Heading variant='landing-panel'>{landingTranslation.questions_title}</Heading>
      {questionsAndAnswers?.map((item, index) => (
        <React.Fragment key={index}>
          <Box layerStyle='landing-q-head'>
            <Heading variant='landing-question'>{item.question}</Heading>
          </Box>
          <Text textStyle='landing-answer'>{item.answer}</Text>
        </React.Fragment>
      ))}
    </Box>
  );
}

Questions.propTypes = {
  landingTranslation: PropTypes.object,
  questionsAndAnswers: PropTypes.array,
};

export function LandingPage02GettingStarted({ responsive }) {
  const { landingTranslation } = useTranslations();
  const { region } = useGlobalForest(globalState);
  let questionsAndAnswers = landingTranslation.questions_and_answers_us;
  const orientation = useBreakpointValue(
    {
      sm: 'vertical',
      md: 'vertical',
      lg: 'horizontal',
    },
    {
      default: 'md',
    }
  );
  let content = useMemo(() => {
    const innerContent = (
      <>
        <GettingStartedColumn src='/img/lp/gs/gs-1.svg' title='Link your cards'>
          <Text textStyle='landing-gs-txt'>{landingTranslation.link_cards_text}</Text>
        </GettingStartedColumn>
        <GettingStartedColumn src='/img/lp/gs/gs-2.svg' title='Go shopping!'>
          <Text textStyle='landing-gs-txt'>{landingTranslation.shopping_text}</Text>
        </GettingStartedColumn>
        <GettingStartedColumn src='/img/lp/gs/gs-3.svg' title='Get cashback'>
          <Text textStyle='landing-gs-txt'>{landingTranslation.shopping_text}</Text>
        </GettingStartedColumn>
      </>
    );
    if (orientation === 'horizontal') {
      return (
        <HStack w='100%' justify='space-around'>
          {innerContent}
        </HStack>
      );
    }
    return (
      <VStack w='100%' alignItems='center'>
        {innerContent}
      </VStack>
    );
  }, [orientation, landingTranslation]);

  if (region === REGIONS.UK) {
    questionsAndAnswers = landingTranslation.questions_and_answers_uk;
  }

  return (
    <Box layerStyle='landing-panel'>
      <Heading variant='landing-panel'>{landingTranslation.getting_started_title}</Heading>
      {content}
      <GettingStartedButton responsive={responsive} />
      <PlugTable landingTranslation={landingTranslation} responsive={responsive} />
      <Questions
        landingTranslation={landingTranslation}
        questionsAndAnswers={questionsAndAnswers}
      />
    </Box>
  );
}

LandingPage02GettingStarted.propTypes = {
  responsive: PropTypes.object,
};
