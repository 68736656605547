import { HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslations } from '~/hooks/useTranslations';
import { MARKETING_PREFERENCE_URL } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { message, TOAST_STATUS } from '~/lib/message';
import accountState from '~/lib/state/account-state';

const fetchMarketingPreference = async () => {
  const response = await fetchUrl(MARKETING_PREFERENCE_URL, {
    method: 'GET',
    accountState,
  });

  return response;
};

const postMarketingPreference = async (body) => {
  const response = await fetchUrl(MARKETING_PREFERENCE_URL, {
    method: 'POST',
    body: JSON.stringify(body),
    accountState,
  });

  return response;
};

export const PromotionalEmailToggle = () => {
  const { genericTranslation } = useTranslations();
  const [data, setData] = useState(null);

  const getUserMarketingPreference = async () => {
    try {
      const response = await fetchMarketingPreference();

      const data = await response.json();
      setData(data);
    } catch (error) {
      handleError(error, 'Could not get marketing preference');
    }
  };

  const handleToggle = async () => {
    const categoryId = data.categories[0].category_id;
    const newSubscribedValue = !data.categories[0].subscribed;
    const body = {};
    body.categories = { [categoryId]: newSubscribedValue };
    try {
      await postMarketingPreference(body);
      const newData = { ...data };
      newData.categories[0].subscribed = newSubscribedValue;
      setData(newData);

      message({
        title: genericTranslation.settings.emailPreferences.success,
        status: TOAST_STATUS.SUCCESS,
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error, 'Could not update marketing preference');
    }
  };

  useEffect(() => {
    getUserMarketingPreference();
  }, []);

  const isLoading = !data;

  const isMarketingPrefToggled = data?.categories[0].subscribed;

  return (
    <VStack gap={2} alignItems='flex-start' width='100%'>
      <Text color='secondary.primary' fontWeight='medium' fontFamily='display' fontSize='sm'>
        {genericTranslation.settings.emailPreferences.header}
      </Text>
      <HStack
        width='100%'
        justifyContent='space-between'
        borderWidth='1px'
        borderColor='secondary.tertiary'
        borderRadius='xl'
        height={16}
        paddingX={4}
        paddingY={6}
      >
        <Text fontFamily='display' fontWeight='medium' fontSize='18px' color='neutral.primary'>
          {genericTranslation.settings.emailPreferences.toggleLabel}
        </Text>
        {isLoading ? null : <Switch isChecked={isMarketingPrefToggled} onChange={handleToggle} />}
      </HStack>
    </VStack>
  );
};
