import { HStack, Link, Show, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { IoArrowBack } from 'react-icons/io5';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslations } from '~/hooks/useTranslations';

export const SupportHeader = forwardRef(({ publisher } = {}, ref) => {
  const { publisher_banner, publisher_name, publisher_home_url } = publisher;
  const { genericTranslation } = useTranslations();

  return (
    <HStack
      ref={ref}
      justifyContent='space-between'
      gap={[4, 5, 10, 16]}
      px={[4, 5, 10, 16]}
      py={2}
      bg={publisher_banner?.bgColor ?? 'secondary.secondary'}
      fontFamily='display'
      fontWeight={500}
      fontSize='sm'
    >
      <Link
        as={ReactRouterLink}
        reloadDocument={true}
        to={publisher_home_url}
        style={{ display: 'flex', alignItems: 'center', columnGap: 2, whiteSpace: 'nowrap' }}
      >
        <IoArrowBack />
        <Show above={publisher_banner?.text ? 'md' : '0px'}>
          {genericTranslation.supportHeader.backTo(publisher_name)}
        </Show>
      </Link>

      {publisher_banner?.text && (
        <HStack>
          <Text as='p'>
            <Text as='span'>{`${publisher_banner.text} `}</Text>
            <Link
              isExternal
              href={publisher_banner.redirect_url}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: 2,
                color: 'secondary_color',
              }}
            >
              {genericTranslation.supportHeader.learnMore} <BsBoxArrowUpRight />
            </Link>
          </Text>
        </HStack>
      )}
    </HStack>
  );
});

SupportHeader.displayName = 'SupportHeader';

SupportHeader.propTypes = {
  publisher: PropTypes.shape({
    publisher_banner: PropTypes.shape({
      bgColor: PropTypes.string,
      redirect_url: PropTypes.string,
      text: PropTypes.string,
    }),
    publisher_home_url: PropTypes.string,
    publisher_name: PropTypes.string,
  }),
};
