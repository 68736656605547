import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PageContainer } from '~/components/page-container';
import { QUERY_PARAMS, TRANSACTIONS_API_URL } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';
import { TransactionCards } from './transaction-cards';
import { TransactionsTable } from './transactions-table';

const TRANSACTION_PAGE_SIZE = 100;
const PAGE_NUMBER = 0;

export function TransactionsPage() {
  const narrow = useBreakpointValue(
    {
      base: true,
      sm: true,
      md: false,
      lg: false,
    },
    {
      fallback: 'md',
    }
  );

  const [transactions, setTransactions] = useState([]);
  const [nextPage, setNextPage] = useState(PAGE_NUMBER);
  const [hasMore, setHasMore] = useState(false);

  const fetchTransactions = useCallback(async (pageNum) => {
    const params = new URLSearchParams();
    params.set(QUERY_PARAMS.SIZE, TRANSACTION_PAGE_SIZE);
    params.set(QUERY_PARAMS.PAGE, pageNum);

    try {
      const resp = await fetchUrl(`${TRANSACTIONS_API_URL}?${params.toString()}`, {
        accountState,
      });

      if (!resp.ok) {
        throw new Error('Bad response: No transactions found');
      }

      const { items, total_pages, current_page } = await resp.json();
      setTransactions((prev) => [...prev, ...items]);
      setHasMore(current_page + 1 < total_pages);
      setNextPage(current_page + 1);
    } catch (error) {
      handleError(error, 'Error loading transactions');
    }
  }, []);

  useEffect(() => {
    fetchTransactions(PAGE_NUMBER);
  }, [fetchTransactions]);

  const loadMoreTransactions = useCallback(() => {
    fetchTransactions(nextPage);
  }, [nextPage, fetchTransactions]);

  return (
    <PageContainer id='page-container'>
      <InfiniteScroll
        dataLength={transactions?.length}
        next={loadMoreTransactions}
        hasMore={hasMore}
        loader={
          <Box>
            <Text>Loading...</Text>
          </Box>
        }
        scrollableTarget='page-container'
      >
        {narrow ? (
          <TransactionCards transactions={transactions} />
        ) : (
          <TransactionsTable transactions={transactions} />
        )}
      </InfiniteScroll>
    </PageContainer>
  );
}
