import { GOCARDLESS } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';

export const getGoCardlessInstitutions = async (searchPhrase) => {
  try {
    const response = await fetchUrl(`${GOCARDLESS}/institutions?${searchPhrase}`, {
      accountState,
    });

    return await response.json();
  } catch (error) {
    console.error('Error fetching institutions:', error);
    throw error;
  }
};

export const getRedirectUrl = async (institutionId) => {
  try {
    const response = await fetchUrl(
      `${GOCARDLESS}/redirect/${institutionId}?redirectUrl=${window.location.href}`,
      {
        accountState,
      }
    );

    return await response.json();
  } catch (error) {
    console.error('Error fetching redirectUrl:', error);
    throw error;
  }
};

export const getPostLinkCallback = async (gcRedirectParam) => {
  try {
    const response = await fetchUrl(`${GOCARDLESS}/link-successful-cb/${gcRedirectParam}`, {
      accountState,
    });

    return await response.json();
  } catch (error) {
    console.error('Error handling institution linking:', error);
    throw error;
  }
};

export const deleteCard = async (institutionId) => {
  try {
    const response = await fetchUrl(`${GOCARDLESS}/${institutionId}`, {
      accountState,
      method: 'DELETE',
    });

    return await response.json();
  } catch (error) {
    console.error('Error unlinking institution:', error);
    throw error;
  }
};
