import { CARDS_API_URL } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';

export const fetchCards = async () => {
  try {
    const response = await fetchUrl(`${CARDS_API_URL}linked-cards`, {
      accountState,
    });

    return await response.json();
  } catch (error) {
    console.error('Error fetching cards:', error);
    throw error;
  }
};

export const fetchLinkToken = async () => {
  try {
    const response = await fetchUrl(`${CARDS_API_URL}link-token`, {
      accountState,
    });

    return await response.json();
  } catch (error) {
    console.error('Error fetching link token:', error);
    throw error;
  }
};

export const setToken = async (publicToken, institution) => {
  try {
    await fetchUrl(`${CARDS_API_URL}public-token`, {
      method: 'POST',
      accountState,
      body: JSON.stringify({
        public_token: publicToken,
        institution_id: institution.institution_id,
        institution_name: institution.name,
      }),
    });
  } catch (error) {
    console.error('Error setting token:', error);
    throw error;
  }
};
