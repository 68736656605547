import { Button } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useFinance } from '~/hooks/useFinance';
import useGlobalForest from '~/hooks/useGlobalForest';
import { useTranslations } from '~/hooks/useTranslations';
import { useUkFinance } from '~/hooks/useUkFinance';
import { OFFER_TYPE, REGIONS } from '~/lib/constants';
import { getBrandPath } from '~/lib/helpers';
import globalState from '~/lib/state/global-state';

export const BrandOfferCtaNoCards = ({ brand }) => {
  const { brandsTranslation } = useTranslations();
  const { region } = useGlobalForest(globalState);
  const { plaidModalIsOpen, linkCards } = useFinance();
  const { isGcModalOpen, setIsGcModalOpen, postModalUrlRef } = useUkFinance();

  const linkBankAccount = useCallback(() => {
    switch (region) {
      case REGIONS.UK: {
        postModalUrlRef.current = getBrandPath(brand);
        setIsGcModalOpen(true);
        break;
      }
      case REGIONS.US:
      default: {
        linkCards();
      }
    }
  }, [region, brand, linkCards, setIsGcModalOpen, postModalUrlRef]);

  return (
    <Button
      variant='baseStyle'
      isDisabled={plaidModalIsOpen || isGcModalOpen}
      px={[6, 6, 12]}
      onClick={linkBankAccount}
    >
      {brandsTranslation.offerType[OFFER_TYPE.CARD_LINKED].brandOfferCtaNoCardsLinked}
    </Button>
  );
};

BrandOfferCtaNoCards.propTypes = {
  brand: PropTypes.object,
};
