import useGlobalForest from '~/hooks/useGlobalForest';
import globalState from '~/lib/state/global-state';

const DEFAULT_PUBLISHER_NAME = 'Cashback Rewards';

const usePublisherName = () => {
  const { publisher_name } = useGlobalForest(globalState);

  return { publisherName: publisher_name || DEFAULT_PUBLISHER_NAME };
};

export default usePublisherName;
