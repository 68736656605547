import { CASHBACK_REDEMPTION_REQUEST, REWARDS_SUMMARY } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';

export const submitRedemptionRequest = async ({
  accountNumber,
  routingNumber,
  accountHolderName,
  accountType,
  redemptionAmount,
  region,
}) => {
  try {
    const resp = await fetchUrl(CASHBACK_REDEMPTION_REQUEST, {
      body: JSON.stringify({
        account_number: accountNumber,
        routing_number: routingNumber,
        account_holder_name: accountHolderName,
        account_type: accountType,
        redemption_amount: redemptionAmount,
        ach_authorization: true,
        region_code: region,
      }),
      method: 'POST',
      accountState,
    });

    return await resp.json();
  } catch (error) {
    console.log('redemption request error:', error);
    throw error;
  }
};

export const getRewardsSummary = async () => {
  try {
    const result = await fetchUrl(REWARDS_SUMMARY, {
      method: 'GET',
      accountState,
    });

    return await result.json();
  } catch (error) {
    console.log('rewards summary error:', error);
    throw error;
  }
};
