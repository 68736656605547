import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

/**
 * @typedef {Object} Account
 * @property {string} emailVerificationEmail
 */

export const AccountStateContext = createContext();
export const AccountActionContext = createContext();

const AccountProvider = ({ children }) => {
  const [emailVerificationEmail, setEmailVerificationEmail] = useState('');

  const alertActionValues = useMemo(
    () => ({
      setEmailVerificationEmail: (email) => {
        setEmailVerificationEmail(email);
      },
    }),
    []
  );

  const alertStateValues = useMemo(() => {
    return {
      emailVerificationEmail,
    };
  }, [emailVerificationEmail]);

  return (
    <AccountActionContext.Provider value={alertActionValues}>
      <AccountStateContext.Provider value={alertStateValues}>
        {children}
      </AccountStateContext.Provider>
    </AccountActionContext.Provider>
  );
};

AccountProvider.propTypes = { children: PropTypes.node.isRequired };

export default AccountProvider;
