import { Grid, GridItem, useToast, VStack } from '@chakra-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageContainer } from '~/components/page-container';
import useAccountSummary from '~/hooks/useAccountSummary';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import useIsNarrowScreen from '~/hooks/useIsNarrowScreen';
import usePublisherName from '~/hooks/usePublisherName';
import { TOAST_DURATION, TOAST_STATUS } from '~/lib/message';
import { RewardsContainer } from '~/pages/account/rewards/rewards-container';
import { TransactionsSummaryPanel } from '~/pages/account/transactions-summary-panel';
import { UserAndCard } from '~/pages/account/user-and-card';

const NUVEI_STATUS_PARAM = 'ppp_status';

export function AccountPage() {
  const isLoggedIn = useIsLoggedIn();
  const narrow = useIsNarrowScreen();
  const [pcHeight, setPcHeight] = useState(0);
  const resizeObserver = useRef(null);
  const toastShownRef = useRef(false);
  const toast = useToast();

  const { publisherName } = usePublisherName();

  const {
    withdrawals,
    lastRedemptionStatus,
    transactions,
    startDate,
    endDate,
    searchFieldPhrase,
    showMoreTransactions,
    onShowMoreTransactions,
    onTransactionDateChange,
    onResetTransactionDate,
    onTransactionSearchFieldChange,
    onResetWithDrawalSearch,
  } = useAccountSummary();

  const transactionSummaryProps = {
    withdrawals,
    transactions,
    searchFieldPhrase,
    startDate,
    endDate,
    showMore: showMoreTransactions,
    onShowMore: onShowMoreTransactions,
    onSearchFieldChange: onTransactionSearchFieldChange,
    onDateChange: onTransactionDateChange,
    onResetDate: onResetTransactionDate,
    onReset: onResetWithDrawalSearch,
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(NUVEI_STATUS_PARAM) === 'OK' && !toastShownRef.current) {
      toast({
        title:
          'Good news! You just earned rewards for your purchase. Check your email for your gift card information.',
        position: 'top',
        status: TOAST_STATUS.SUCCESS,
        duration: TOAST_DURATION.NORMAL,
      });
      toastShownRef.current = true;
    }
  }, [toast]);

  const onPageContainer = useCallback((ele) => {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
    if (ele) {
      setPcHeight(ele.clientHeight);

      resizeObserver.current = new ResizeObserver(() => {
        if (ele.clientHeight !== pcHeight) {
          setPcHeight(ele.clientHeight);
        }
      });

      resizeObserver.current.observe(ele);
    } else {
      setPcHeight(null);
    }
  }, []); // note -- do not add pcHeight as a dependency!

  const ucHeight = useMemo(() => {
    if (narrow || !pcHeight) {
      return undefined;
    }
    return `${pcHeight - 80}px`;
  }, [pcHeight, narrow]);

  if (isLoggedIn) {
    return (
      <Fragment>
        <Helmet>
          <title>Account | {publisherName}</title>
        </Helmet>
        <PageContainer ref={onPageContainer}>
          {narrow ? (
            <VStack w='100%' alignItems='stretch' data-role='narrow-vstack' p='20px'>
              <UserAndCard />
              <RewardsContainer lastRedemptionStatus={lastRedemptionStatus} />
              <TransactionsSummaryPanel narrow {...transactionSummaryProps} />
            </VStack>
          ) : (
            <Grid
              width='100%'
              minHeight='100%'
              columnGap='40px'
              templateColumns='320px auto'
              rowGap='40px'
              templateRows='310px auto'
            >
              <GridItem rowSpan={2} alignSelf='flex-start' minHeight={ucHeight}>
                <UserAndCard />
              </GridItem>
              <GridItem>
                <RewardsContainer lastRedemptionStatus={lastRedemptionStatus} />
              </GridItem>
              <GridItem pb='40px'>
                <TransactionsSummaryPanel narrow={narrow} {...transactionSummaryProps} />
              </GridItem>
            </Grid>
          )}
        </PageContainer>
      </Fragment>
    );
  }
}
