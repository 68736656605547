import { useEffect, useState } from 'react';
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT ?? 'dev';

export const useConfig = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await fetch('/config/config.json');
      const data = await response.json();
      setConfig({ ...data, env: ENVIRONMENT });
    };

    if (config === null) {
      fetchConfig().then();
    }
  }, [config]);

  return config;
};
