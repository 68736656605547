import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useGlobalForest from '~/hooks/useGlobalForest';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import { useTranslations } from '~/hooks/useTranslations';
import { ACCOUNT_ROUTE } from '~/lib/constants';
import { goUrl, neutralizeEvent } from '~/lib/helpers';
import accountState from '~/lib/state/account-state';
import globalState from '~/lib/state/global-state';

export function HeaderAccountButtons({ setSettingsModalIsOpen, setSignInIsOpen, setSignUpIsOpen }) {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const { sign_in_url, sign_up_url } = useGlobalForest(globalState);
  const { user_email } = useGlobalForest(accountState);
  const { genericTranslation } = useTranslations();

  const goToSignIn = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_in_url) {
        goUrl(sign_in_url);
      } else {
        setSignInIsOpen(true);
      }
    },
    [setSignInIsOpen, sign_in_url]
  );

  const goToSignUp = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_up_url) {
        goUrl(sign_up_url);
      } else {
        setSignUpIsOpen(true);
      }
    },
    [setSignUpIsOpen, sign_up_url]
  );

  // logged in, not mobile
  if (isLoggedIn) {
    return (
      <Menu>
        <MenuButton>
          <Text textStyle='header-menu-item'>{genericTranslation.userMenu.buttonText}</Text>
        </MenuButton>

        <MenuList>
          <MenuItem bg='none' onClick={neutralizeEvent} cursor='default'>
            <VStack align='start'>
              <Text color='secondary.primary' fontSize='sm' fontWeight='medium'>
                {genericTranslation.userMenu.account}
              </Text>
              <Text color='neutral.primary' fontWeight='medium'>
                {user_email}
              </Text>
            </VStack>
          </MenuItem>
          <MenuDivider />
          <MenuGroup>
            <Link to={ACCOUNT_ROUTE}>
              <MenuItem>
                <Text color='neutral.primary' fontWeight='medium'>
                  {genericTranslation.userMenu.myWallet}
                </Text>
              </MenuItem>
            </Link>
            <MenuItem
              color='neutral.primary'
              fontWeight='medium'
              onClick={() => {
                setSettingsModalIsOpen(true);
              }}
            >
              {genericTranslation.userMenu.settings}
            </MenuItem>
            <MenuItem
              color='neutral.primary'
              fontWeight='medium'
              onClick={() => accountState.do.signOut(navigate)}
            >
              {genericTranslation.userMenu.logOut}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    );
  }

  // logged out, not mobile
  return (
    <HStack gap={6}>
      <Button variant='userSignOutline' onClick={goToSignIn} mr={0}>
        {genericTranslation.signIn.cta}
      </Button>

      <Button variant='userSign' onClick={goToSignUp}>
        {genericTranslation.signUp.cta}
      </Button>
    </HStack>
  );
}

HeaderAccountButtons.propTypes = {
  setSettingsModalIsOpen: PropTypes.func.isRequired,
  setSignInIsOpen: PropTypes.func.isRequired,
  setSignUpIsOpen: PropTypes.func.isRequired,
};
