import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { getRewardsSummary, submitRedemptionRequest } from '~/api';
import {
  ACCOUNT_TYPE,
  MAX_WITHDRAWAL_AMOUNT,
  MIN_WITHDRAWAL_AMOUNT,
  WITHDRAWAL_FAIL_STATUS,
  WITHDRAWAL_PROCESSING_STATUS_LIST,
} from '~/lib/constants';
import { handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { getRegion } from '~/lib/helpers';
import { useConfig } from './useConfig';
import { useTranslations } from './useTranslations';

/**
 * @typedef {'FAIL' | 'SUBMITTED' | 'PROCESSING' | 'SUCCESS'} RedemptionStatus
 */

export const useRewardsRedemption = ({ lastRedemptionStatus }) => {
  const config = useConfig();
  const region = getRegion(config) ?? 'US';
  const { rewardsTranslation: t } = useTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentRedemptionStatus, setCurrentRedemptionStatus] = useState(
    /** @type {RedemptionStatus} */ ('')
  );

  const [availableCashback, setAvailableCashback] = useState();
  const [pendingCashback, setPendingCashback] = useState();
  const [totalEarnedCashback, setTotalEarnedCashback] = useState();

  const initialValues = {
    redemptionAmount: '',
    accountNumber: '',
    reAccountNumber: '',
    routingNumber: '',
    accountHolderName: '',
    accountType: ACCOUNT_TYPE.CHECKING,
  };

  const validationSchema = Yup.object().shape({
    redemptionAmount: Yup.number()
      .required('Redemption amount is required')
      .min(MIN_WITHDRAWAL_AMOUNT, t.validation.minWithdrawalAmount(MIN_WITHDRAWAL_AMOUNT))
      .max(MAX_WITHDRAWAL_AMOUNT, t.validation.maxWithdrawalAmount(MAX_WITHDRAWAL_AMOUNT))
      .test('is-valid-amount', t.validation.redemptionAmount, function (value) {
        return value <= availableCashback && value <= MAX_WITHDRAWAL_AMOUNT;
      }),
    accountNumber: Yup.string()
      .required(t.validation.accountNumber)
      .length(9, t.validation.accountNumberLength),
    reAccountNumber: Yup.string()
      .required(t.validation.reenterAccountNumber)
      .oneOf([Yup.ref('accountNumber'), null], t.validation.accountNumberMatch),
    routingNumber: Yup.string()
      .length(9, t.validation.routingNumberLength)
      .required(t.validation.routingNumber),
    accountHolderName: Yup.string().required(t.validation.accountHolderName),
    accountType: Yup.string()
      .required(t.validation.accountType)
      .oneOf([ACCOUNT_TYPE.CHECKING, ACCOUNT_TYPE.SAVINGS]),
    acceptedTerms: Yup.boolean().required(t.validation.required).oneOf([true], t.validation.terms),
  });

  const onRedemptionRequest = async (values, { resetForm }) => {
    try {
      const data = await submitRedemptionRequest({
        accountNumber: values.accountNumber,
        routingNumber: values.routingNumber,
        accountHolderName: values.accountHolderName,
        accountType: values.accountType,
        redemptionAmount: values.redemptionAmount,
        region,
        fetchUrl,
      });
      setCurrentRedemptionStatus(data.status);

      if (WITHDRAWAL_PROCESSING_STATUS_LIST.includes(data.status)) {
        setStepNumber(stepNumber + 1);
        triggerConfetti(() => setIsModalOpen(false));
        resetForm();
      } else if (data.status === WITHDRAWAL_FAIL_STATUS) {
        resetForm();
        setStepNumber(0);
      }
    } catch (submitErr) {
      console.log('error', submitErr);
      resetForm();
      setStepNumber(0);
      handleError(submitErr);
    }
  };

  const { resetForm, values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      onSubmit: onRedemptionRequest,
      initialValues,
    });

  const handleNext = () => {
    switch (stepNumber) {
      case 0:
      case 1:
        setStepNumber(stepNumber + 1);
        break;

      case 2:
        handleSubmit();
        break;

      case 3:
        break;
    }
  };

  const isProcessing =
    WITHDRAWAL_PROCESSING_STATUS_LIST.includes(lastRedemptionStatus) ||
    WITHDRAWAL_PROCESSING_STATUS_LIST.includes(currentRedemptionStatus);

  useEffect(() => {
    const loadRewardsSummary = async () => {
      setIsLoading(true);
      const summary = await getRewardsSummary();
      setAvailableCashback(summary.available_cashback ?? 0);
      setPendingCashback(summary.pending_cashback ?? 0);
      setTotalEarnedCashback(summary.total_earned_cashback ?? 0);
      setIsLoading(false);
    };

    loadRewardsSummary();
  }, []);

  const triggerConfetti = (onComplete) => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
      onComplete?.();
    }, 5000);
  };

  const handleCloseModal = () => {
    resetForm();
    setIsModalOpen(false);
    setShowConfetti(false);
    setStepNumber(0);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return {
    isModalOpen,
    onOpenModal: handleOpenModal,
    onCloseModal: handleCloseModal,
    currentRedemptionStatus,
    onNext: handleNext,
    onGoToStep: setStepNumber,
    isLoading,
    isSubmitting,
    isProcessing,
    setIsLoading,
    stepNumber,
    showConfetti,
    availableCashback,
    pendingCashback,
    totalEarnedCashback,
    values,
    errors,
    handleBlur,
    handleChange,
  };
};
