import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { MoneyShieldIcon } from '~/components/icons/money-shield-icon';
import { PiggyBankIcon } from '~/components/icons/piggy-bank-icon';
import { TermsBulletIcon } from '~/components/icons/terms-bullet-icon';
import { useFinance } from '~/hooks/useFinance';
import useGlobalForest from '~/hooks/useGlobalForest';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { useTranslations } from '~/hooks/useTranslations';
import { OFFER_TYPE } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import { longDate } from '~/lib/helpers';
import globalState from '~/lib/state/global-state';
import { BrandOfferCta } from '~/pages/brand/brand-offer-cta/brand-offer-cta';
import { GiftCardPurchaseBlock } from '~/pages/brand/gift_card/gift-card-purchase-block';
import { CashBackRates } from './brand-offer-text-blocks/cash-back-rates';
import { OfferInstructions } from './brand-offer-text-blocks/offer-instructions';
import { OfferTextBlock } from './brand-offer-text-blocks/offer-text-block';

export function BrandOffer({ offer, brand, brandHasMultiOffers, openOfferId, selectOpenOfferId }) {
  const { brandsTranslation, giftCardTranslation } = useTranslations();
  const { offerAmountText, actionCopy } = useTranslationHelpers();
  const { region } = useGlobalForest(globalState);
  const { cards } = useFinance();
  const hasLinkedCards = cards.length > 0;
  const [giftCardVisible, setGiftCardVisible] = useState(false);

  const showFullOfferDetails = !brandHasMultiOffers || openOfferId === offer.offer_uid;

  const endDate = useMemo(() => {
    if (!offer.end_date) {
      return '';
    }
    const date = longDate(offer.end_date, false);
    if (!date) {
      return '';
    }
    return `Offer expires ${date}`;
  }, [offer.end_date]);

  const hasCommissionGroups = offer.commission_groups?.length > 0;
  const showBottomOfferBlock = !giftCardVisible;
  let actionTextBlockTitle = '';
  let actionTextBlockBody = '';
  try {
    actionTextBlockTitle = actionCopy({ brandsTranslation, offerType: offer.offer_type });
    actionTextBlockBody = actionCopy({
      brandsTranslation,
      offerType: offer.offer_type,
      body: true,
    });
  } catch (error) {
    handleError(error);
  }

  return (
    <VStack justify='center' pb={10} mx={9}>
      <Heading variant='offer-main'>
        {hasCommissionGroups
          ? brandsTranslation.preOfferText.hasCommissionGroups
          : brandsTranslation.preOfferText.noCommissionGroups}
        &nbsp;
        {offerAmountText({
          offerAmount: brand.max_customer_offer,
          region,
          rewardType: offer.reward_type,
        })}
      </Heading>
      <Text variant='offer-ends'>{endDate}</Text>
      {giftCardVisible ? (
        <GiftCardPurchaseBlock
          brand={brand}
          offer={offer}
          hideGiftCard={() => {
            setGiftCardVisible(false);
          }}
        />
      ) : (
        <BrandOfferCta
          brand={brand}
          offer={offer}
          showGiftCard={() => {
            setGiftCardVisible(true);
          }}
          hasLinkedCards={hasLinkedCards}
        />
      )}
      {showFullOfferDetails ? (
        <>
          <VStack spacing={7} alignItems='flex-start' my={10}>
            <OfferInstructions offer={offer} hasLinkedCards={hasLinkedCards} />
            <CashBackRates offer={offer} />
            <OfferTextBlock
              headingText={actionTextBlockTitle}
              headingIcon={(iconSize) => <PiggyBankIcon size={iconSize} />}
            >
              <Text>{actionTextBlockBody}</Text>
            </OfferTextBlock>
            {offer?.description && (
              <OfferTextBlock
                headingText={brandsTranslation.details.details}
                headingIcon={(iconSize) => <MoneyShieldIcon size={iconSize} />}
              >
                <Text>{offer.description}</Text>
              </OfferTextBlock>
            )}
            <OfferTextBlock
              headingText={brandsTranslation.details['terms&cond']}
              headingIcon={(iconSize) => <TermsBulletIcon size={iconSize} />}
            >
              {offer.terms_and_conditions && <Text>{offer.terms_and_conditions}</Text>}
              {offer.offer_type === OFFER_TYPE.GIFT_CARD && (
                <Text>{giftCardTranslation.returnPolicy}</Text>
              )}
            </OfferTextBlock>
            {brandHasMultiOffers && (
              <Box my={4} w='100%'>
                <Center>
                  <Button
                    onClick={() => {
                      selectOpenOfferId(null);
                    }}
                    variant='outline'
                  >
                    {brandsTranslation.hideDetailsBtn}
                  </Button>
                </Center>
              </Box>
            )}
          </VStack>

          {showBottomOfferBlock && (
            <BrandOfferCta
              brand={brand}
              offer={offer}
              showGiftCard={() => {
                window.scroll({ top: 0, behavior: 'smooth' });
                setGiftCardVisible(true);
              }}
              hasLinkedCards={hasLinkedCards}
            />
          )}
        </>
      ) : (
        <Button
          onClick={() => {
            selectOpenOfferId(offer.offer_uid);
          }}
          variant='outline'
        >
          {brandsTranslation.offerDetailsBtn}
        </Button>
      )}
    </VStack>
  );
}

BrandOffer.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object.isRequired,
  state: PropTypes.object,
  brandHasMultiOffers: PropTypes.bool,
  openOfferId: PropTypes.string,
  selectOpenOfferId: PropTypes.func.isRequired,
};
