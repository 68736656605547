import { Forest } from '@wonderlandlabs/forest';
import { isEqual, pick } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

export const NO_FOREST_ERR_MSG = 'useGlobalForest hook must be passed a Forest object';
export const INVALID_WATCH_PROPS_LIST =
  '"watchedProperties" arguments must be either an array or undefined.';

export default function useGlobalForest(forest, watchedProperties) {
  // listens to a shared state, simply copies its value out
  const [forestValue, setForestValue] = useState(forest?.value ?? {});
  const forestValueRef = useRef(forestValue);
  forestValueRef.current = forestValue;

  useEffect(() => {
    if (watchedProperties) {
      if (watchedProperties && !Array.isArray(watchedProperties)) {
        throw new Error(INVALID_WATCH_PROPS_LIST);
      }

      const valueMapper = (allValues) => pick(allValues, watchedProperties);
      const propSub = forest.select((newValue) => {
        if (!isEqual(newValue, forestValueRef.current)) {
          setForestValue(newValue);
        }
      }, valueMapper);
      return () => propSub.unsubscribe();
    }

    const sub = forest.subscribe(setForestValue);
    return () => sub?.unsubscribe();
  }, [forest, watchedProperties]);

  if (!(forest instanceof Forest)) {
    throw new Error(NO_FOREST_ERR_MSG);
  }

  return forestValue;
}
