export const NO_HEADERS = Symbol('no headers');

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const MIN_CHARS = 8;

export const ACCOUNT_MODE = {
  neutral: 'neutral',
  signingUp: 'signingUp',
  signingIn: 'signingIn',
  terms: 'terms',
};

export const OFFER_TYPE = {
  GIFT_CARD: 'GIFT_CARD',
  VOUCHER_CODE: 'VOUCHER_CODE',
  AFFILIATE: 'AFFILIATE',
  CARD_LINKED: 'CARD_LINKED',
};

export const OFFER_LOCATION = {
  IN_STORE_ONLINE: 'IN_STORE_ONLINE',
  IN_STORE_ONLY: 'IN_STORE_ONLY',
  ONLINE_ONLY: 'ONLINE_ONLY',
};

export const REWARD_TYPE = {
  CASHBACK_DOLLAR: 'CASHBACK_DOLLAR',
  CASHBACK_PERCENTAGE: 'CASHBACK_PERCENTAGE',
  DISCOUNT_DOLLAR: 'DISCOUNT_DOLLAR',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENTAGE',
};

export const PREFS_TERMS = 'prefs.termsRevisionAccepted';

export const COMMON_PASSWORDS = [
  '00000',
  '000000',
  '11111',
  '111111',
  '112233',
  '121212',
  '123123',
  '123123123',
  '123321',
  '1234',
  '12345',
  '123456',
  '1234567',
  '12345678',
  '123456789',
  '1234567890',
  '12345678910',
  '123abc',
  '123qwe',
  '1q2w3e4r',
  '1q2w3e4r5t',
  '1qaz2wsx',
  '1qaz2wsx3edc',
  '54321',
  '55555',
  '555555',
  '654321',
  '666666',
  '7777777',
  '987654321',
  '987654321',
  'Aa123456',
  'BvtTest123',
  'Chegg123',
  'Password',
  'Testing1',
  'aaaaaa',
  'abc123',
  'abcd1234',
  'amanda',
  'andrea',
  'andrew',
  'angel',
  'animoto',
  'anthony',
  'asdasd',
  'asdf',
  'asdfgh',
  'asdfghjkl',
  'ashley',
  'babygirl',
  'bailey',
  'banana',
  'baseball',
  'basketball',
  'batman',
  'brandon',
  'bubbles',
  'buster',
  'butterfly',
  'changeme',
  'charlie',
  'cheese',
  'chelsea',
  'chicken',
  'chocolate',
  'computer',
  'cookie',
  'daniel',
  'danielle',
  'diamond',
  'dragon',
  'dubsmash',
  'elizabeth',
  'familia',
  'family',
  'fitness',
  'flower',
  'football',
  'friends',
  'fuckyou',
  'g_czechout',
  'gabriel',
  'george',
  'ginger',
  'hannah',
  'harley',
  'hello',
  'hunter',
  'iloveyou',
  'jackson',
  'jasmine',
  'jennifer',
  'jessica',
  'jesus',
  'jordan',
  'joshua',
  'justin',
  'lauren',
  'letmein',
  'liverpool',
  'livetest',
  'lovely',
  'loveme',
  'madison',
  'maggie',
  'maria',
  'matthew',
  'melissa',
  'michael',
  'michelle',
  'mickey',
  'monkey',
  'morgan',
  'nicole',
  'oliver',
  'olivia',
  'orange',
  'pakistan',
  'password',
  'password1',
  'peanut',
  'pepper',
  'pokemon',
  'princess',
  'purple',
  'q1w2e3r4t5y6',
  'qazwsx',
  'qwe123',
  'qwert',
  'qwerty',
  'qwerty123',
  'qwertyuiop',
  'robert',
  'samantha',
  'secret',
  'shadow',
  'shopping',
  'soccer',
  'softball',
  'sophie',
  'summer',
  'sunshine',
  'superman',
  'taylor',
  'test',
  'test1',
  'testing',
  'thomas',
  'tigger',
  'victoria',
  'welcome',
  'whatever',
  'william',
  'yellow',
  'zinch',
  'zxcvbnm',
].map((t) => `${t}`.toLowerCase());

/* Each form error should be kept general and start with a verb so it may
   be prepended with "Password must ...", "Name should ...", "Field must ...", etc. */
export const FORM_ERRORS = {
  UPPER_AND_LOWER_CASE: 'contain at least one uppercase and one lowercase character',
  ONE_NUMBER: 'contain at least one number',
  PASSWORD_SIZE: `contain at least ${MIN_CHARS} characters`,
  COMMON: 'not be a common password',
  NOT_UN: 'not be the same as the account name',
  SPECIAL_CHARS: 'contain a special symbol',
  SPECIAL_CHARS_2: '(^$*.[\\]{}()?\\-"!@#%&/,><\':;|_~`+=)',
  MISMATCH: 'be the same',
  REQUIRED: 'be present',
  EMAIL: 'be a valid email address',
  NAME: 'not contain special characters',
  REQUIRED_FIELD: 'is required.',
  ACCOUNT_NUMBER_MISMATCH_MESSAGE: 'Account number does not match',
};

export const LINKED_CARDS_ERROR = {
  NEED_AUTHENTICATION: 'NEED_AUTHENTICATION',
};

export const GO_CARDLESS_REDIRECT_PARAM = 'ref';

export const WITHDRAWAL_FAIL_STATUS = 'FAIL';
export const WITHDRAWAL_PROCESSING_STATUS_LIST = ['SUBMITTED', 'PROCESSING'];
export const WITHDRAWAL_SUCCESS_STATUS = 'SUCCESS';

export const REGIONS = { UK: 'UK', US: 'US' };

export const MAX_GIFT_CARD_LIMIT = 200;

export const MIN_WITHDRAWAL_AMOUNT = 40;
export const MAX_WITHDRAWAL_AMOUNT = 500;

export const CREATE_ZENDESK_TICKET = '/web/v1/support/ticket';

export const CARDS_API_URL = '/web/v1/card/';
export const GOCARDLESS = '/web/v1/card/gocardless';
export const CARD_ACCESS_TOKEN_URL = '/web/v1/card/access-token/';

export const USER_URL = '/web/v1/user/';
export const FORGOT_PASSWORD_URL = '/web/v1/user/forgot-password';
export const RESET_PASSWORD_URL = '/web/v1/user/reset-password';
export const ONE_TIME_TOKEN_URL = '/web/v1/user/exchange-token';
export const CHANGE_PASSWORD_URL = '/web/v1/user/change-password';
export const MARKETING_PREFERENCE_URL = '/web/v1/user/marketing/preference';

export const TOC_API_URL = '/web/v1/terms/';
export const MARKETING_URL = '/web/v1/terms/marketing/';

export const BRANDS_API_URL = '/web/v1/brands';

export const ORDER_API_URL = '/web/v1/orders';
export const GIFT_CARDS_BALANCE = '/web/v1/orders/gift-card/balance';

export const ORDER_NUVEI_API_URL = '/web/v1/orders/nuvei';

export const CATEGORIES_API_URL = '/web/v1/categories';

export const TRANSACTIONS_API_URL = '/web/v1/transactions';
export const WITHDRAWAL_API_URL = '/web/v1/withdrawal';
export const CASHBACK_REDEMPTION_REQUEST = '/web/v1/redemption';

export const REWARDS_SUMMARY = '/web/v1/rewards/summary';


/** Query Params */
export const QUERY_PARAMS = {
  SEARCH_TEXT: 'search_text',
  CATEGORY_ID: 'category_id',
  PAGE: 'page',
  SIZE: 'size',
  SORT: 'sort',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

/* Routes */
export const ACCOUNT_ROUTE = '/account';
export const ACCOUNT_CARDS_ROUTE = '/account/cards';
export const ACCOUNT_TRANSACTIONS_ROUTE = '/account/transactions';
export const BRANDS_ROUTE = '/brands';
export const BRANDS_SEARCH_ROUTE = `${BRANDS_ROUTE}/search`;
export const BRANDS_NOT_FOUND_ROUTE = `${BRANDS_ROUTE}/not-found`;

export const AUTH_LIFESPAN = import.meta?.env?.VITE_AUTH_LIFESPAN ?? 30 * 60 * 1000; // lifespan of access_token, in seconds
export const CURRENT_TOC_VERSION_NUM = 1;
export const BAD_BODY = Symbol('bad body');
export const MAX_GIFT_CARD_PURCHASES_PER_WEEK = 5;

export const PDFS = {
  [REGIONS.UK]: {
    ACCESSIBILITY: '/pdfs/uk/accessibility.pdf',
    TERMS_AND_CONDITIONS: '/pdfs/uk/user-terms.pdf',
    PRIVACY_POLICY: '/pdfs/uk/privacy-policy.pdf',
  },
  [REGIONS.US]: {
    ACCESSIBILITY: '/pdfs/us/accessibility.pdf',
    TERMS_AND_CONDITIONS: '/pdfs/us/user-terms.pdf',
    PRIVACY_POLICY: '/pdfs/us/privacy-policy.pdf',
  },
};

export const SORT_BY = {
  OFFER_RANK: 'offerRank',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ACCOUNT_TYPE = {
  CHECKING: 'CHECKING',
  SAVINGS: 'SAVINGS',
};
