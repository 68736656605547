import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Countdown } from '~/components/countdown/countdown';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { FORGOT_PASSWORD_URL } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { responseStatusIsGood } from '~/lib/helpers';
import { message, TOAST_DURATION, TOAST_STATUS } from '~/lib/message';
import accountState from '~/lib/state/account-state';
import { ErrorRow } from '../common/error-row';

const initialValues = {
  email: '',
};

export const ForgotPasswordModalContent = () => {
  const { genericTranslation } = useTranslations();
  const [isCountdownActive, setIsCountdownActive] = useState('');
  const { emailValidation } = useValidationSchema();

  const onSubmit = async (values) => {
    try {
      setIsCountdownActive(true);
      const response = await fetchUrl(FORGOT_PASSWORD_URL, {
        method: 'POST',
        body: JSON.stringify({ email: values.email }),
        accountState,
      });

      if (responseStatusIsGood(response)) {
        message({
          title: genericTranslation.resetPassword.emailSent,
          status: TOAST_STATUS.SUCCESS,
          duration: TOAST_DURATION.NORMAL,
          isClosable: true,
        });
      }
    } catch (error) {
      handleError(error);
      setIsCountdownActive(false);
    }
  };

  const handleCountdownComplete = () => {
    setIsCountdownActive(false);
  };

  const validationSchema = Yup.object().shape({
    email: emailValidation,
  });

  const { handleSubmit, values, handleBlur, handleChange, errors } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const isDisabled = !values.email || isCountdownActive;

  return (
    <ModalContent as='form' onSubmit={handleSubmit}>
      <ModalHeader mb={0}>{genericTranslation.resetPassword.header}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pt={0}>
        <Text color='secondary.primary' mb={4} textAlign='center'>
          {genericTranslation.resetPassword.requestSubHeader}
        </Text>
        <Stack direction='column' spacing={4}>
          <VStack>
            <FormControl>
              <FormLabel>{genericTranslation.email}</FormLabel>
              <Input
                name='email'
                layerStyle='sign-in-field'
                value={values.email}
                placeholder={genericTranslation.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormControl>
            <ErrorRow isPresent={Boolean(errors.email)}>{errors.email}</ErrorRow>
          </VStack>
        </Stack>
      </ModalBody>

      <ModalFooter display='flex' flexDir='column' alignItems='flex-start'>
        <Button width='100%' isDisabled={isDisabled} type='submit' mb={4}>
          {genericTranslation.resetPassword.submit}
        </Button>
        {isCountdownActive && (
          <Text fontFamily='display' fontWeight='medium' fontSize='sm' color='neutral.primary'>
            {`${genericTranslation.resetPassword.countdown} `}
            <Countdown as='span' seconds={60} onComplete={handleCountdownComplete} />
          </Text>
        )}
      </ModalFooter>
    </ModalContent>
  );
};
