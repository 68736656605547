import { useCallback, useEffect, useState } from 'react';
import { MARKETING_URL } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';
import useIsLoggedIn from './useIsLoggedIn';

export const useMarketingConsent = () => {
  const [currentMarketingText, setCurrentMarketingText] = useState(null);
  const [revision, setRevision] = useState(null);
  const [loadingMarketingText, setLoadingMarketingText] = useState(true);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetchUrl(MARKETING_URL, {
          accountState,
        });

        const data = await response.json();

        setRevision(data?.marketing_revision_id ?? null);
        setCurrentMarketingText(data?.text ?? '');
        setLoadingMarketingText(false);
      } catch {
        setCurrentMarketingText('');
        setLoadingMarketingText(false);
      }
    };

    if (isLoggedIn && currentMarketingText === null) {
      fetchConfig().then();
    }
  }, [currentMarketingText, isLoggedIn]);

  const setMarketingPreference = useCallback(
    (pref = false) => {
      if (revision === null) {
        return;
      }

      fetchUrl(MARKETING_URL, {
        body: JSON.stringify({ marketing_agreement_id: revision, accepted: pref }),
        method: 'POST',
        accountState,
      });
    },
    [revision]
  );

  return {
    currentMarketingText,
    loadingMarketingText,
    setMarketingPreference,
  };
};
