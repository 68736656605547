import { Box, Button, Heading, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import { useConfig } from '~/hooks/useConfig';
import { FLAGS, useFeatureFlag } from '~/hooks/useFeatureFlag';
import useGlobalForest from '~/hooks/useGlobalForest';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import {
  ACCOUNT_ROUTE,
  GIFT_CARDS_BALANCE,
  ORDER_API_URL,
  ORDER_NUVEI_API_URL,
  REGIONS,
} from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { formatCurrency, getBrandPath, getRegion, isValidUrl } from '~/lib/helpers';
import accountState from '~/lib/state/account-state';
import { CardRules } from '~/pages/brand/gift_card/card-rules';
import HostedGiftCardForm from '~/pages/brand/gift_card/hosted-gift-card-form';
import { GiftCardDenominationInput } from './gift-card-denomination-input';

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT ?? 'dev';
const DEV_PAGE = 'https://dev.rewards.finfare.com';
const DEFAULT_MAX_PRICE = 200;
const DEFAULT_MIN_PRICE = 1;

const GIFT_CARD_STATE = {
  NEW: '01-new',
  SENDING_FOR_TOKEN: '02-sending',
  GOT_TOKEN: '03-got-token',
  OPENING_PAGE: '04-opening',
};

const getOfferMax = (offer) => {
  if (offer.denominations) {
    return Math.max(...offer.denominations);
  }
  return offer?.max_gift_card_value != undefined
    ? Number(offer.max_gift_card_value)
    : DEFAULT_MAX_PRICE;
};

const getGiftCardPrices = (offer) => {
  if (offer.denominations) {
    return offer.denominations;
  }
  let minPrice = offer?.min_gift_card_value ? Number(offer.min_gift_card_value) : DEFAULT_MIN_PRICE;
  const maxPrice = getOfferMax(offer);
  let increment = 25;

  if (minPrice < 10) {
    minPrice = 25;
  }

  if (maxPrice > 100) {
    increment = 50;
    minPrice = 50;
  }

  let prices = [];

  let price = minPrice;
  while (price < maxPrice && prices.length < 5) {
    if (price > 0) {
      prices.push(price);
    }
    price += increment;
    if (price > 100) {
      increment = 1000;
    }
  }
  if (prices[prices.length - 1] < maxPrice) {
    prices.push(maxPrice);
  }
  return prices;
};

export const GiftCardPurchaseBlock = memo(({ brand, hideGiftCard, offer }) => {
  const { giftCardTranslation } = useTranslations();
  const { emailValidation } = useValidationSchema();

  const config = useConfig();
  const region = getRegion(config);
  const { enabled: nuveiIsLive } = useFeatureFlag(FLAGS.NUVEI_IS_LIVE);
  const isLoggedIn = useIsLoggedIn();

  const [cardState, setCardState] = useState(GIFT_CARD_STATE.NEW);
  const [cardToken, setCardToken] = useState(null);
  const [showCustomDenomBtn, setShowCustomDenomBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [remainingWeeklyValue, setRemainingWeeklyValue] = useState('');
  const [remainingWeeklyPurchases, setRemainingWeeklyPurchases] = useState(null);

  const { user_email: email } = useGlobalForest(accountState);

  const offerMin = offer?.min_gift_card_value
    ? Number(offer.min_gift_card_value)
    : DEFAULT_MIN_PRICE;
  const offerMax = getOfferMax(offer);

  const validationSchema = Yup.object().shape({
    email: emailValidation.label('Email'),
    cardValue: Yup.number()
      .label('Card value')
      .required()
      .min(
        offerMin,
        giftCardTranslation.validation.belowOfferMin(
          formatCurrency(offerMin, config?.region ?? REGIONS.US)
        )
      )
      .max(
        offerMax,
        giftCardTranslation.validation.exceedsOfferMax(
          formatCurrency(offerMax, config?.region ?? REGIONS.US)
        )
      )
      .max(
        remainingWeeklyValue,
        giftCardTranslation.validation.exceedsWeeklyValue(
          formatCurrency(remainingWeeklyValue, config?.region ?? REGIONS.US)
        )
      ),
  });

  const onSubmit = useCallback(
    async (values) => {
      if (cardState !== GIFT_CARD_STATE.NEW) {
        return;
      }

      setCardState(GIFT_CARD_STATE.SENDING_FOR_TOKEN);

      try {
        let urlBase = window.location.origin;
        if (ENVIRONMENT === 'dev' && !isValidUrl(urlBase)) {
          urlBase = DEV_PAGE;
        }

        const info = {
          amount: values.cardValue,
          offer_uid: offer.offer_uid,
          success_url: `${urlBase}${ACCOUNT_ROUTE}`,
          failure_url: `${urlBase}${getBrandPath(brand)}`,
          cancel_url: `${urlBase}${getBrandPath(brand)}`,
        };

        let endpoint = ORDER_API_URL;
        if (nuveiIsLive) {
          endpoint = ORDER_NUVEI_API_URL;
        }

        setIsLoading(true);
        const response = await fetchUrl(endpoint, {
          method: 'POST',
          body: JSON.stringify(info),
          accountState,
        });
        const data = await response.json();

        if (!data.payment_token) {
          throw new CustomError({
            title: 'Could not create gift card',
            description: 'No payment token',
          });
        }

        if (data?.payment_token && nuveiIsLive) {
          window.location.href = data.payment_token;

          const url = new URL(window.location.href);
          if (url === data.payment_token) {
            setIsLoading(false);
          }
        } else if (data?.payment_token && !nuveiIsLive) {
          setCardState(GIFT_CARD_STATE.GOT_TOKEN);
          setCardToken(data);
          setCardState(GIFT_CARD_STATE.OPENING_PAGE);
        }
      } catch (err) {
        handleError(err, 'Could not create gift card');
        setIsLoading(false);

        if (!nuveiIsLive) {
          setCardState(GIFT_CARD_STATE.NEW);
          setCardToken(null);
        }
      }
    },
    [brand, nuveiIsLive, offer, cardState]
  );

  const { handleSubmit, values, errors, setFieldValue } = useFormik({
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    initialValues: {
      email,
      cardValue: null,
    },
    onSubmit,
  });

  useEffect(() => {
    const getGiftCardBalance = async () => {
      try {
        const response = await fetchUrl(GIFT_CARDS_BALANCE, {
          method: 'GET',
          accountState,
        });

        const data = await response.json();

        setRemainingWeeklyPurchases(data.remaining_purchases);
        setRemainingWeeklyValue(data.balance);
      } catch (err) {
        handleError(err, 'Cannot get gift card balance');
      }
    };

    if (isLoggedIn && brand) {
      getGiftCardBalance();
    }
  }, [isLoggedIn, brand]);

  const res = useBreakpointValue(
    {
      base: {
        flexDir: 'column',
        flexBasis: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      sm: {
        flexDir: 'column',
        flexBasis: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      md: {
        flexDir: 'row',
        flexBasis: '50%',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      },
      lg: {
        flexDir: 'row',
        flexBasis: '50%',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      },
    },
    {
      fallback: 'md',
    }
  );

  const exceedsWeeklyPurchasesMessage =
    remainingWeeklyPurchases === 0 ? giftCardTranslation.validation.exceededPurchasesError : '';
  const restErrorMessage = errors?.email || exceedsWeeklyPurchasesMessage;

  if (cardState === GIFT_CARD_STATE.OPENING_PAGE) {
    return <HostedGiftCardForm token={cardToken?.payment_token} />;
  }

  return (
    <Box
      display='flex'
      flexDir={res.flexDir}
      alignItems={res.alignItems}
      justifyContent={res?.justifyContent}
      gap={8}
      width='100%'
      mt={4}
    >
      <CardRules brand={brand} offer={offer} email={email} flexBasis={res.flexBasis} width='100%' />

      <VStack as='form' onSubmit={handleSubmit} noValidate gap={4} flexBasis={res.flexBasis}>
        <Heading variant='subhead' mb={-1}>
          {values.cardValue
            ? `Card value ${formatCurrency(values.cardValue, region)} Selected`
            : 'Select a value'}
        </Heading>
        <HStack gap={4} justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap'>
          {getGiftCardPrices(offer).map((price) => (
            <Box key={price} display='flex' justifyContent='center' flexBasis='min-content'>
              <Button
                price={price}
                width='6rem'
                borderColor='secondary_color'
                variant={
                  !showCustomDenomBtn && price === values.cardValue ? 'thin' : 'outline-thin'
                }
                onClick={() => {
                  setFieldValue('cardValue', Number.parseFloat(price));
                  setShowCustomDenomBtn(false);
                }}
                isDisabled={price > remainingWeeklyValue}
                backgroundColor={price > remainingWeeklyValue ? '#eaeaea' : undefined}
                pointerEvents={price > remainingWeeklyValue ? 'none' : undefined}
                borderRadius='99px'
              >
                {formatCurrency(price, region)}
              </Button>
            </Box>
          ))}
          {!offer?.denominations ? (
            <Box display='flex' justifyContent='center' flexBasis='min-content'>
              <Button
                onClick={() => {
                  setFieldValue('cardValue', '');
                  setShowCustomDenomBtn(true);
                }}
                variant='outline-thin'
                width='6rem'
                backgroundColor={showCustomDenomBtn ? 'brand.primary' : undefined}
                color={showCustomDenomBtn ? 'surface.white' : undefined}
                borderRadius='99px'
              >
                {giftCardTranslation.other}
              </Button>
            </Box>
          ) : null}
        </HStack>
        {showCustomDenomBtn && (
          <GiftCardDenominationInput
            min={offerMin}
            max={offerMax}
            value={values.cardValue}
            onChange={(event) => {
              setFieldValue('cardValue', Number.parseFloat(event.target.value));
            }}
            remainingWeeklyValue={remainingWeeklyValue}
            errorText={errors?.cardValue}
          />
        )}
        <ErrorRow isPresent={Boolean(errors?.cardValue)} mt={-2}>
          {errors?.cardValue}
        </ErrorRow>

        <HStack gap={3} alignItems='baseline' justify='space-between' width='100%'>
          <Button type='submit' isLoading={isLoading} isDisabled={remainingWeeklyPurchases === 0}>
            Pay with Credit Card
          </Button>
          <Button
            variant='outline'
            onClick={() => {
              hideGiftCard();
              setFieldValue('cardValue', '');
            }}
          >
            Cancel
          </Button>
        </HStack>
        {restErrorMessage && (
          <ErrorRow isPresent={Boolean(restErrorMessage)}>{restErrorMessage}</ErrorRow>
        )}
      </VStack>
    </Box>
  );
});

GiftCardPurchaseBlock.displayName = 'GiftCardPurchaseBlock';

GiftCardPurchaseBlock.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object.isRequired,
  hideGiftCard: PropTypes.func.isRequired,
};
