import { format, parse } from 'date-fns';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ACCOUNT_TRANSACTIONS_ROUTE,
  QUERY_PARAMS,
  TRANSACTIONS_API_URL,
  WITHDRAWAL_API_URL,
} from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import accountState from '~/lib/state/account-state';

const PAGE_SIZE = 100;
const TRANSACTION_PAGE_SIZE = 20;
const PAGE_NUMBER = 0;

const DATE_FORMAT = 'yyyy-MM-dd';
const formatDate = (date) => format(date, DATE_FORMAT);

const useAccountSummary = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [withdrawals, setWithdrawals] = useState([]);
  const [lastRedemptionStatus, setLastRedemptionStatus] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [showMoreTransactions, setShowMoreTransactions] = useState(false);

  const startDate = useMemo(() => {
    const dateVal = searchParams.get(QUERY_PARAMS.START_DATE);
    return dateVal ? parse(dateVal, DATE_FORMAT, new Date()) : undefined;
  }, [searchParams]);

  const endDate = useMemo(() => {
    const dateVal = searchParams.get(QUERY_PARAMS.END_DATE);
    return dateVal ? parse(dateVal, DATE_FORMAT, new Date()) : undefined;
  }, [searchParams]);

  const searchPhrase = searchParams.get(QUERY_PARAMS.SEARCH_TEXT) || '';
  const [searchFieldPhrase, setSearchFieldPhrase] = useState(searchPhrase);

  useEffect(() => {
    const loadWithdrawals = async () => {
      try {
        const response = await fetchUrl(
          `${WITHDRAWAL_API_URL}?page=${PAGE_NUMBER}&size=${PAGE_SIZE}`,
          { accountState }
        );
        const data = await response.json();
        if (!Array.isArray(data?.content)) {
          throw new CustomError({
            title: 'Bad data for withdrawals',
            description: 'Cannot find withdrawals',
          });
        }

        const { content } = data;

        setWithdrawals(content);
        setLastRedemptionStatus(content[0]?.status || '');
      } catch (err) {
        handleError(err, 'Error loading withdrawals list');
      }
    };

    loadWithdrawals();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      const params = new URLSearchParams(searchParams);
      params.set(QUERY_PARAMS.SIZE, TRANSACTION_PAGE_SIZE);
      params.set(QUERY_PARAMS.PAGE, PAGE_NUMBER);

      try {
        const resp = await fetchUrl(`${TRANSACTIONS_API_URL}?${params.toString()}`, {
          accountState,
        });

        const { items, total_pages, current_page } = await resp.json();
        setTransactions(items);
        setShowMoreTransactions(current_page + 1 < total_pages);
      } catch (error) {
        handleError(error, 'Error loading transactions');
      }
    };

    fetchTransactions();
  }, [searchParams, startDate, endDate, searchPhrase]);

  const debouncedSetSearchParams = useMemo(
    () =>
      debounce((value) => {
        const currentSearchParams = new URLSearchParams(searchParams);
        if (value) {
          currentSearchParams.set(QUERY_PARAMS.SEARCH_TEXT, value);
        } else {
          currentSearchParams.delete(QUERY_PARAMS.SEARCH_TEXT);
        }
        setSearchParams(currentSearchParams);
      }, 300),
    [searchParams, setSearchParams]
  );

  const handleOnShowMoreTransactions = () => {
    navigate(ACCOUNT_TRANSACTIONS_ROUTE);
  };

  const handleResetSearch = () => {
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.delete(QUERY_PARAMS.SEARCH_TEXT);
    currentSearchParams.delete(QUERY_PARAMS.START_DATE);
    currentSearchParams.delete(QUERY_PARAMS.END_DATE);
    setSearchFieldPhrase('');
    setSearchParams(currentSearchParams);
  };

  const handleTransactionDateChange = (dateEvt) => {
    const [startDate, endDate] = dateEvt;
    const currentSearchParams = new URLSearchParams(searchParams);

    if (startDate) {
      currentSearchParams.set(QUERY_PARAMS.START_DATE, formatDate(startDate));
    } else {
      currentSearchParams.delete(QUERY_PARAMS.START_DATE);
    }

    if (endDate) {
      currentSearchParams.set(QUERY_PARAMS.END_DATE, formatDate(endDate));
    } else {
      currentSearchParams.delete(QUERY_PARAMS.END_DATE);
    }

    setSearchParams(currentSearchParams);
  };

  const handleResetTransactionDate = () => {
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.delete(QUERY_PARAMS.START_DATE);
    currentSearchParams.delete(QUERY_PARAMS.END_DATE);
    setSearchParams(currentSearchParams);
  };

  const handleTransactionSearchFieldChange = (e) => {
    const { value } = e.target;
    setSearchFieldPhrase(value);
    debouncedSetSearchParams(value);
  };

  return {
    withdrawals,
    lastRedemptionStatus,
    transactions,
    startDate,
    endDate,
    searchFieldPhrase,
    showMoreTransactions,
    onShowMoreTransactions: handleOnShowMoreTransactions,
    onTransactionDateChange: handleTransactionDateChange,
    onResetTransactionDate: handleResetTransactionDate,
    onTransactionSearchFieldChange: handleTransactionSearchFieldChange,
    onResetWithDrawalSearch: handleResetSearch,
  };
};

export default useAccountSummary;
