import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const PiggyBankIcon = ({ size = 8, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 31 31' fill='none'>
      <path
        d='M23.2078 30.4873H18.1313C17.6376 30.4873 17.2374 30.0871 17.2374 29.5934V28.7175H10.7026V29.5934C10.7026 30.0871 10.3024 30.4873 9.80871 30.4873H5.08645C4.83386 30.4873 4.5911 30.3807 4.42216 30.1921C4.25157 30.0051 4.1712 29.7541 4.1958 29.5015L4.5583 25.9783C3.28219 24.8941 2.47847 23.4064 2.1045 22.5912H0.89564C0.401928 22.5912 0.00170898 22.191 0.00170898 21.6973V17.1489C0.00170898 16.6552 0.401928 16.2549 0.89564 16.2549H2.2636L4.34343 13.606C3.70537 12.5677 2.84917 10.893 3.15589 9.817C3.27727 9.39381 3.55939 9.06413 3.95141 8.88698C5.29969 8.28501 7.88471 9.73991 9.24446 10.6158C11.4407 9.9925 21.3314 7.61251 24.2658 14.3654C26.9082 20.4425 24.3478 24.0018 23.2915 25.1254L24.0886 29.4343C24.1378 29.6967 24.0673 29.9657 23.8967 30.1691C23.7278 30.3741 23.4752 30.4922 23.2095 30.4922L23.2078 30.4873ZM19.0252 28.6978H22.1318L21.4429 24.9728C21.3839 24.6497 21.5053 24.3216 21.7579 24.115C21.8973 23.9985 25.2467 21.1068 22.6239 15.0707C20.029 9.10349 9.47738 12.4037 9.37076 12.4365C9.11325 12.5201 8.8262 12.4791 8.59985 12.3266C7.20237 11.39 5.58017 10.6076 4.88963 10.5174C4.99132 11.0636 5.53916 12.1986 6.1805 13.1516C6.39701 13.4747 6.38225 13.8979 6.14277 14.203L3.40193 17.6951C3.23298 17.9116 2.97382 18.0379 2.69826 18.0379H1.78957V20.7984H2.69826C3.07388 20.7984 3.41013 21.0346 3.53971 21.3873C3.54791 21.4102 4.38771 23.677 6.02139 24.8498C6.28219 25.0368 6.42161 25.3501 6.38881 25.6683L6.07716 28.6962H8.91314V27.8203C8.91314 27.3266 9.31336 26.9263 9.80707 26.9263H18.1313C18.625 26.9263 19.0252 27.3266 19.0252 27.8203V28.6962V28.6978Z'
        fill={color}
      />
      <path
        d='M18.1311 16.6142C18.0212 16.6142 17.9113 16.5945 17.8031 16.5518C13.8288 14.9838 11.3209 16.4239 11.2142 16.4862C10.7911 16.7372 10.2432 16.601 9.99062 16.1795C9.73639 15.7579 9.86761 15.2134 10.2859 14.9559C10.4204 14.8739 13.6123 12.9728 18.4608 14.887C18.9217 15.0674 19.1464 15.5874 18.9644 16.0466C18.825 16.3976 18.4887 16.6125 18.1328 16.6125L18.1311 16.6142Z'
        fill={color}
      />
      <path
        d='M27.6873 17.5146H24.1198C23.626 17.5146 23.2258 17.1144 23.2258 16.6207C23.2258 16.127 23.626 15.7268 24.1198 15.7268H27.6873C28.2072 15.7268 28.6304 15.3036 28.6304 14.7836C28.6304 14.2637 28.2072 13.8405 27.6873 13.8405H27.2215C26.7277 13.8405 26.3275 13.4403 26.3275 12.9466C26.3275 12.4529 26.7277 12.0526 27.2215 12.0526H27.6873C29.193 12.0526 30.4183 13.2779 30.4183 14.7836C30.4183 16.2894 29.193 17.5163 27.6873 17.5163V17.5146Z'
        fill={color}
      />
      <path
        d='M13.9141 8.11606C11.8114 8.11606 10.1006 6.40529 10.1006 4.3025C10.1006 2.19972 11.8114 0.487305 13.9141 0.487305C16.0169 0.487305 17.7277 2.19808 17.7277 4.30086C17.7277 6.40365 16.0169 8.11442 13.9141 8.11442V8.11606ZM13.9141 2.27681C12.7988 2.27681 11.8901 3.1855 11.8901 4.30086C11.8901 5.41623 12.7988 6.32492 13.9141 6.32492C15.0295 6.32492 15.9382 5.41623 15.9382 4.30086C15.9382 3.1855 15.0295 2.27681 13.9141 2.27681Z'
        fill={color}
      />
    </Icon>
  );
};

PiggyBankIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
