import { Image, Spinner, useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalForest from '~/hooks/useGlobalForest';
import { BRANDS_ROUTE } from '~/lib/constants';
import { asPx } from '~/lib/helpers';
import globalState from '~/lib/state/global-state';
import { HEADER_ICON_HEIGHT } from '~/theme/constants';

const Logo = () => {
  const { config, isConfigLoaded } = useGlobalForest(globalState);
  const navigate = useNavigate();

  const responsive = useBreakpointValue(
    {
      base: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: true,
      },
      sm: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: true,
      },
      md: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: false,
      },
    },
    { fallback: 'md', ssr: false }
  );

  const logoUrl = useMemo(() => {
    if (!isConfigLoaded) {
      return '';
    }
    const { logo_img_url, logo_img_sq_url } = config.theme.images;

    if (responsive.squareLogo) {
      return logo_img_sq_url ?? logo_img_url;
    }
    return logo_img_url;
  }, [isConfigLoaded, config, responsive]);

  if (!isConfigLoaded) {
    return <Spinner />;
  }

  return (
    <Image
      src={logoUrl}
      id='site-logo'
      role='button'
      tabIndex={0}
      onClick={() => navigate(BRANDS_ROUTE)}
      onKeyDown={({ key }) => key === 'Enter' && navigate(BRANDS_ROUTE)}
      cursor='pointer'
      flexShrink={0}
      height={asPx(responsive.height)}
      width='auto'
      alt='logo'
    />
  );
};

export default Logo;
