import { useMemo } from 'react';
import useGlobalForest from '~/hooks/useGlobalForest';
import globalState from '~/lib/state/global-state';

export const useBgGradient = () => {
  const { theme } = useGlobalForest(globalState);
  return useMemo(() => {
    const colorValues = Object.values(theme.colors.lp);

    return [
      `linear(to-t, ${colorValues.join(', ')})`,
      `linear(to-t, ${colorValues.join(', ')})`,
      `linear(to-bl, ${colorValues.join(', ')})`,
    ];
  }, [theme]);
};
