import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CURRENT_TOC_VERSION_NUM, PREFS_TERMS, TOC_API_URL } from '~/lib/constants';
import { DEFAULT_TITLE, handleError, isBadStatusError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { neutralizeEvent } from '~/lib/helpers';
import accountState from '~/lib/state/account-state';
import useIsLoggedIn from './useIsLoggedIn';

const INITIAL_TERMS_REVISION_ACCEPTED = 0;

export const useTerms = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);

  const setRevision = useCallback((num) => {
    if (localStorage) {
      localStorage.setItem(PREFS_TERMS, num);
    }

    setTermsModalIsOpen(num < CURRENT_TOC_VERSION_NUM);
  }, []);

  const checkTermsRemote = useCallback(async () => {
    try {
      const result = await fetchUrl(TOC_API_URL + 'accepted', { accountState });

      const data = await result.json();

      setRevision(data?.revision || INITIAL_TERMS_REVISION_ACCEPTED);
    } catch (err) {
      handleError(err, DEFAULT_TITLE);
      setRevision(INITIAL_TERMS_REVISION_ACCEPTED);
    }
  }, [setRevision]);

  const checkTermsLocal = useCallback(() => {
    if (localStorage && localStorage.getItem('access_token') && localStorage.getItem(PREFS_TERMS)) {
      setRevision(Number(localStorage.getItem(PREFS_TERMS)));
      return;
    }

    // could not find record of most recent terms acceptance in localStorage; querying API now instead
    checkTermsRemote().catch(() => {
      /* do nothing */
    });
  }, [checkTermsRemote, setRevision]);

  const acceptTerms = useCallback(
    async (e) => {
      try {
        neutralizeEvent(e);
        setRevision(CURRENT_TOC_VERSION_NUM);

        if (!isLoggedIn) {
          return;
        }

        await fetchUrl(TOC_API_URL + CURRENT_TOC_VERSION_NUM, {
          method: 'POST',
          accountState,
        });
      } catch (err) {
        handleError(err, DEFAULT_TITLE);

        if (isBadStatusError(err) && err.response?.status === 401) {
          accountState.signOut(navigate);
        }
      } finally {
        setTermsModalIsOpen(false);
      }
    },
    [navigate, setRevision, isLoggedIn]
  );

  // check for terms acceptance on login; clear localStorage on logout
  useEffect(() => {
    if (isLoggedIn) {
      checkTermsLocal();
    } else {
      if (localStorage) {
        localStorage.removeItem(PREFS_TERMS);
      }
    }
  }, [isLoggedIn, checkTermsLocal]);

  return {
    acceptTerms,
    termsModalIsOpen,
  };
};
