import * as PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteCard, getPostLinkCallback } from '~/api/ukInstitutions';
import { useConfig } from '~/hooks/useConfig';
import { useFinance } from '~/hooks/useFinance';
import { useTranslations } from '~/hooks/useTranslations';
import { GO_CARDLESS_REDIRECT_PARAM, REGIONS } from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';
import { logEvent } from '~/lib/logEvent';
import { message, TOAST_STATUS } from '~/lib/message';

export const UkFinanceContext = createContext({
  isGcModalOpen: false,
  setIsGcModalOpen: () => {},
  postModalUrlRef: { current: '' },
  unlinkCard: () => {},
});

export const UkFinanceProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { financeTranslation } = useTranslations();
  const { refetchInstitutions } = useFinance();
  const config = useConfig();
  const isUk = config?.publisher?.region === REGIONS.UK;

  const [isGcModalOpen, setIsGcModalOpen] = useState(false);
  const postModalUrlRef = useRef('');

  const gcRedirectParam = searchParams.get(GO_CARDLESS_REDIRECT_PARAM);
  const handlingRedirectParamRef = useRef(false);

  const unlinkCard = useCallback(
    async (institutionId) => {
      logEvent('GoCardLess', { action: 'unlink_card' });

      try {
        const data = await deleteCard(institutionId);

        message({
          title: financeTranslation.cardUnlinked,
          status: TOAST_STATUS.SUCCESS,
          description: data?.message,
        });

        return refetchInstitutions();
      } catch (err) {
        handleError(err, financeTranslation.cannotUnlink);
      }
    },
    [refetchInstitutions, financeTranslation]
  );

  const navAfterLinkCard = useCallback(() => {
    if (postModalUrlRef.current) {
      // send user to specified post-linking URL
      navigate(postModalUrlRef.current);
    } else {
      // stay on current path but remove GoCardless param(s)
      const url = new URL(window.location.href);
      url.searchParams.delete(GO_CARDLESS_REDIRECT_PARAM);
      navigate(`${url.pathname}?${url.searchParams.toString()}`, { replace: true });
    }

    postModalUrlRef.current = '';
  }, [navigate]);

  const postSuccessLinkCard = useCallback(
    async (param) => {
      try {
        const data = await getPostLinkCallback(param);

        if (!data) {
          throw new CustomError({
            title: financeTranslation.cardNotLinked,
          });
        }

        message({
          title: financeTranslation.cardLinked,
          status: TOAST_STATUS.SUCCESS,
        });
        refetchInstitutions();
      } catch (error) {
        handleError(error, financeTranslation.cardNotLinked);
      }
    },
    [refetchInstitutions, financeTranslation]
  );

  useEffect(() => {
    const handleRedirectParam = async () => {
      await postSuccessLinkCard(gcRedirectParam);

      navAfterLinkCard();
      handlingRedirectParamRef.current = false;
    };

    if (isUk && gcRedirectParam && !handlingRedirectParamRef.current) {
      handlingRedirectParamRef.current = true;
      handleRedirectParam();
    }
  }, [isUk, gcRedirectParam, navAfterLinkCard, postSuccessLinkCard]);

  const value = useMemo(
    () => ({
      isGcModalOpen,
      setIsGcModalOpen,
      postModalUrlRef,
      unlinkCard,
    }),
    [isGcModalOpen, setIsGcModalOpen, postModalUrlRef, unlinkCard]
  );

  return isUk ? (
    <UkFinanceContext.Provider value={value}>{children}</UkFinanceContext.Provider>
  ) : (
    children
  );
};

UkFinanceProvider.propTypes = {
  children: PropTypes.node,
};
