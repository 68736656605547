import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const TermsBulletIcon = ({ size = 8, color = 'currentColor' }) => {
  return (
    <Icon viewBox='0 0 25 32' width='auto' height={size} fill='none' color={color}>
      <g stroke='currentColor'>
        <rect width={22.4} height={28.4} x={1.3} y={0.8} strokeWidth={1.6} rx={2.2} />
        <path
          strokeLinecap='round'
          strokeWidth={2}
          d='M6.499 8.906h12M6.499 15h12M6.499 21.094h12'
        />
      </g>
    </Icon>
  );
};

TermsBulletIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
