export const genericTranslation = {
  welcome: 'Welcome',
  getStarted: 'Get Started',
  name: 'Name',
  email: 'Email',
  error: 'Error',
  back: 'Back',
  account: 'Account',
  amount: 'Amount',
  search: 'Search',
  submit: 'Submit',
  submitting: 'Submitting',
  cancel: 'Cancel',
  loading: 'Loading',
  loadMore: 'Load More',
  clear: 'Clear',
  clearDates: 'Clear Date(s)',
  continue: 'Continue',
  confirm: 'Confirm',
  date: 'Date',
  selectDateRange: 'Select a date range',

  password: 'Password',
  confirmPassword: 'Confirm Password',

  settings: {
    settings: 'Settings',
    account: 'Account',
    changePassword: {
      button: 'Change Password',
      header: 'Change password',
      subHeader: 'Provide details below',
      currentPlaceholder: 'Enter current password',
      newPlaceholder: 'Enter new password',
      confirmNewPlaceholder: 'Confirm new password',
      update: 'Update password',
      success: 'Your account password has been successfully changed.',
      errorMessage: 'Unable to change password',
      currentError: 'Current password is incorrect',
    },
    emailPreferences: {
      header: 'Preferences',
      toggleLabel: 'Promotional Emails',
      success: 'Your email preferences have been saved with the updated changes!',
    },
    deleteAccount: 'Delete Account',
  },
  signIn: {
    successTitle: 'Sign in successful',
    successDescription: 'You are now signed in.',
    cta: 'Sign In',
    instructions: 'Log in to see what’s on offer',
  },
  emailVerification: {
    title: 'Email verification has expired',
    instructions:
      'Please note that the verification link in the previous email has expired. You will need to request a new one.',
    cta: 'Resend Verification Email',
    countdown: 'Resend link in',
  },
  signUp: {
    successTitle: 'Almost there!',
    successDescription:
      "We've sent a verification email to your inbox. Please click the link to verify your account. Can't find it? Check your spam or junk folder.",
    alreadyRegistered: 'Already registered?',
    dontHaveAccount: 'Don’t have an account yet?',
    freeForever: 'Free forever, no credit card needed',
    cta: 'Sign Up',
  },
  resetPassword: {
    cta: 'Forgot Password?',
    header: 'Reset Password',
    requestSubHeader: "Enter your email and we'll send you a link to reset your password.",
    submit: 'Send Reset Link',
    countdown: 'Resend code in',
    emailSent:
      'A link has been sent to your email address. Please check your inbox. If you do not see the email, kindly check your spam folder or request it again.',
    success: 'Your account password has been successfully changed.',
    newPlaceholder: 'Enter new password',
    confirmNewPlaceholder: 'Confirm new password',
    reset: 'Reset password',
    invalid:
      "Something went wrong while attempting to reset your password. Please make sure you'ure using the link provided in the email.",
    newSubHeader: 'Choose a new password',
  },
  contact: {
    title: 'Contact us',
    heading: 'Get in touch',
    info: 'Have any questions? We’d love to hear from you.',
    success: 'Thank you for contacting us. We will get back to you as soon as possible.',
    subject: 'Subject',
    commentsQuestion: 'Comments/Questions',
  },
  validation: {
    email: 'Invalid email address',
    emailRequired: 'Email is required',
    nameRequired: 'Name is required',
    commentRequired: 'Comment is required',
    xRequired: (x) => `${x}*`,
    password: 'Password must be at least 8 characters',
    passwordRequired: 'Password is required',
    passwordMatch: 'Passwords must match',
    passwordIsNotUsername: 'Password cannot be the same as the username',
    passwordIsTooCommon: 'Password is too common, please choose another one',
    passwordIsNew: 'Password cannot be the same as the current one',
    passwordNumber: 'Password must contain at least one number',
    passwordSymbol: 'Password must contain at least one special character',
    passwordCase: 'Password must contain both lower and upper case letters',
    passwordIsNotLongEnough: (num) => `Password must be at least ${num} characters`,
    passwordHaveNumber: 'Password must contain at least one number',
    termsRequired: 'Please accept the Terms & Conditions to continue',
    must: (message) => `Must ${message}`,
    maxLength: (num) => `Maximum ${num} characters`,
    charsRemaining: (num, maxNum) => `${num} / ${maxNum} characters remaining`,
  },
  userMenu: {
    buttonText: 'My Account',
    account: 'Account',
    myWallet: 'My Wallet',
    settings: 'Settings',
    logOut: 'Log Out',
  },
  supportHeader: {
    backTo: (publisherName) => `Back to ${publisherName}`,
    learnMore: 'Learn More',
  },
  errorPage: {
    general: {
      title: 'Oops',
      message: 'Sorry, an unexpected error has occured.',
      actionText: 'Go Back Home',
    },
    404: {
      title: 'Page Not Found',
      message:
        'Oops! The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.',
      actionText: 'Go Back Home',
    },
  },
};
