import { PDFS, REGIONS } from '~/lib/constants';
import { logEvent } from '~/lib/logEvent';
export default function globalStateFactory(utilities) {
  const { computeThemeUpdate, message, window } = utilities;
  return {
    $value: {
      openOfferID: '',
      currentLocation: '',
      sso: null,
      isConfigLoaded: false,
      sign_in_url: '',
      sign_up_url: '',
      sign_out_url: '',
      stores_we_love: [],
      publisher_id: '',
      publisher_name: '',
      theme: {},
      themeExtension: {},
      config: {},
      favicon: '/img/pub/P1003/favicon.ico',
      publisher_home_url: '',
      region: REGIONS.US,
    },
    actions: {
      injectFavicon(state) {
        const { favicon } = state.value;
        if (typeof window === 'undefined') return;
        let link = window.document.querySelector("link[rel*='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          window.document.getElementsByTagName('head')[0].appendChild(link);
        }

        // Set the new favicon path
        link.href = favicon;
      },
      logEvent(state, eventName, data) {
        logEvent(eventName, data);
      },
      message(state, toastData, textAlternative) {
        message(toastData, textAlternative);
      },
      parseConfig(state, config) {
        const { publisher, account, theme } = config;
        const {
          publisher_id,
          publisher_home_url,
          publisher_name,
          favicon,
          stores_we_love,
          region,
        } = publisher;

        const { sso, sign_in_url, sign_up_url, sign_out_url } = account;
        state.do.set_sso(sso);
        state.do.set_sign_in_url(sign_in_url);
        state.do.set_sign_up_url(sign_up_url);
        state.do.set_sign_out_url(sign_out_url);
        state.do.set_publisher_id(publisher_id);
        state.do.set_publisher_name(publisher_name);
        state.do.set_theme(theme);
        state.do.set_favicon(favicon);
        state.do.set_config(config);
        state.do.set_publisher_home_url(publisher_home_url);
        state.do.set_stores_we_love(stores_we_love ?? []);
        state.do.set_region(region);
        state.do.set_isConfigLoaded(true);
        try {
          const extension = computeThemeUpdate(theme);
          state.do.set_themeExtension(extension);
        } catch (err) {
          console.error('cannot set extension', err.message);
        }
        state.do.injectFavicon();
      },
    },
    name: 'globalState',
    selectors: {
      hasToken(state) {
        const { sso } = state.value; // note - assuming that config has been pre-loaded
        if (typeof window === 'undefined' || !sso) {
          return false;
        }
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.has('token');
      },
      pdfLocations(state) {
        const { region } = state.value;

        return PDFS[region] ?? PDFS.US;
      },
    },
  };
}
