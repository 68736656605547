import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslations } from '~/hooks/useTranslations';
import { USER_URL } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { Countdown } from '../countdown/countdown';

const resendEmailVerification = async (email, onSuccess) => {
  try {
    await fetchUrl(
      `${USER_URL}resend-verification?email=${email}`,
      {
        accountState: { value: {} },
        method: 'GET',
      },
      { noAccessToken: true }
    );
    onSuccess();
  } catch (e) {
    handleError(e, 'Cannot send email verification');
  }
};

export function EmailVerificationModal({ isOpen, email, onClose }) {
  const { genericTranslation } = useTranslations();
  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const onSuccess = () => {
    setIsCountdownActive(true);
  };

  const handleCountdownComplete = () => {
    setIsCountdownActive(false);
  };

  const handleClick = async () => {
    await resendEmailVerification(email, onSuccess);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered blockScrollOnMount>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader mb={0} pb={10} layerStyle='modal-sm-header'>
          <VStack>
            <Image alt='icon' src='/icons/danger-icon.svg' />
            <Text>{genericTranslation.emailVerification.title}</Text>
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Text textStyle='modal-info' color='neutral.primary'>
            {genericTranslation.emailVerification.instructions}
          </Text>
        </ModalBody>
        <ModalFooter pb={0}>
          <VStack spacing={4} w='100%' alignItems='normal'>
            <Button w='100%' onClick={handleClick} isDisabled={isCountdownActive}>
              {genericTranslation.emailVerification.cta}
            </Button>
            {isCountdownActive && (
              <Text>
                {genericTranslation.emailVerification.countdown}{' '}
                <Countdown as='span' seconds={60} onComplete={handleCountdownComplete} />
              </Text>
            )}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  email: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
