import * as PropTypes from 'prop-types';
import { memo } from 'react';

function BankDefaultLogoBase({ size = 64, color = 'white' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 64 64'
    >
      <g fill={color} clipPath='url(#a)'>
        <path d='M31.953 0 0 22.603v3.37h64v-3.37L31.953 0ZM32 8.792c2.704 0 4.895 2.35 4.895 5.246 0 2.897-2.19 5.249-4.895 5.249-2.704 0-4.895-2.349-4.895-5.249 0-2.9 2.19-5.246 4.895-5.246Z' />
        <path d='M32.489 16.877v.946h-1.006v-.87c-.485-.043-.97-.198-1.27-.411l-.1-.071.315-1.177.182.114c.37.233.81.368 1.209.368.435 0 .714-.206.714-.525 0-.218-.096-.46-.797-.733-.762-.297-1.542-.736-1.542-1.756 0-.822.506-1.443 1.334-1.654v-.903h1.008v.835c.39.03.728.129 1.053.302l.123.066-.315 1.151-.22-.106a2.118 2.118 0 0 0-1.013-.264c-.39 0-.624.167-.624.446 0 .244.218.416.901.708 1.013.406 1.446.949 1.446 1.806 0 .858-.544 1.522-1.4 1.728M11.277 55.846V35.451c0-2.339 1.774-4.239 3.953-4.239 2.18 0 3.954 1.9 3.954 4.239v20.395h8.864V35.451c0-2.339 1.775-4.239 3.954-4.239 2.178 0 3.95 1.9 3.95 4.239v20.395h8.683V35.451c0-2.339 1.772-4.239 3.953-4.239 2.182 0 3.954 1.9 3.954 4.239v20.395h6.352V29.17H5.109v26.676h6.168ZM.716 58.284h62.571c.386 0 .698.335.698.748v4.216c0 .414-.312.751-.7.751H.715c-.385 0-.7-.335-.7-.75v-4.217c0-.413.313-.75.7-.75v.002Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill={color} d='M0 0h64v64H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
const BankDefaultLogo = memo(BankDefaultLogoBase);

export default BankDefaultLogo;

BankDefaultLogoBase.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
