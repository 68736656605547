import { useContext } from 'react';
import { AccountActionContext } from '~/lib/state/contexts/AccountContext';

export const useAccountAction = () => {
  const accountActions = useContext(AccountActionContext);
  if (!accountActions) {
    throw new Error('useAccountAction must be used within AccountProvider');
  }
  return accountActions;
};
