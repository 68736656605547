import { DEFAULT_HEADERS } from '~/lib/constants';

export function headers(headerConfig, access_token) {
  let token = {};
  let headers = headerConfig?.headers ?? {};
  let noAccessToken = headerConfig?.noAccessToken ?? false;

  if (!noAccessToken && access_token) {
    token = { Authorization: `Bearer ${access_token}` };
  }
  return {
    ...DEFAULT_HEADERS,
    ...headers,
    ...token,
  };
}
