import { headers } from '~/lib/fetch-url-helpers';
import { currentTimeInMS, responseStatusIsGood } from '~/lib/helpers';
import { NO_HEADERS } from './constants';
import { BadStatusError } from './errors';

/**
 * fetchUrl wraps the native 'fetch' and does two things:
 *
 * 1. It uses the headers method to inject the access_token into the request.
 * 2. If necessary, it refreshes the auth state to get a valid auth_token.
 *
 * There are two levels of cancellation you can use to reduce the auto-headers.
 *
 * 1. passing {noAccessToken: true} in headerConfig will remove the access token (but leave in the publisher ID)
 * 2. passing NO_HEADERS obviates the entire headersAsync() call. Do this for any third party APIs or static
 * resources for faster execution.
 *
 * You can, for instance, put headers in the config AND pass NO_HEADERS and your config headers will be used.
 *
 */

let refreshAuthInFlight = false;
let requestQueue = [];

export async function headersAsync({ headerConfig, accountState }) {
  if (!accountState) {
    throw new Error('You forgot to pass accountState as an option to fetchUrl()');
  }
  const { access_token } = accountState.value;

  const { access_token_timeout, refresh_token } = accountState.value;

  const needsRefresh =
    refresh_token &&
    access_token &&
    access_token_timeout &&
    access_token_timeout < currentTimeInMS();

  if (needsRefresh) {
    if (refreshAuthInFlight) {
      // can throw, if refresh fails
      await new Promise((resolve, reject) => {
        requestQueue.push((isResolved) => {
          if (isResolved) {
            resolve();
          } else {
            reject();
          }
        });
      })
        .then(() => {
          /* do nothing */
        })
        .catch(() => {
          /* do nothing */
        });
    } else {
      refreshAuthInFlight = true;
      await accountState.do
        .refreshAuth(undefined, true)
        .then(() => {
          requestQueue.forEach((fn) => fn(true));
        })
        .catch(() => {
          requestQueue.forEach((fn) => fn(false));
        })
        .finally(() => {
          refreshAuthInFlight = false;
          requestQueue = [];
        });
    }
  }

  return headers(headerConfig, access_token);
}

/**
 *
 * @param url {string}
 * @param config {object} any global fetch params.
 * @param customHeaderConfig {object | NO_HEADERS} argument to accountState
 * @returns {Promise<Response>}
 */
export default async function fetchUrl(url, config, customHeaderConfig = {}) {
  if (customHeaderConfig === NO_HEADERS) {
    return fetch(url, config);
  }

  // Do not try to pass globalState into config. It creates a circular dependency.
  const { accountState, ...fetchConfig } = config;

  const headers = await headersAsync({ accountState, customHeaderConfig });

  const fetchOptions = { ...fetchConfig, headers };

  const response = await fetch(url, fetchOptions);

  if (!responseStatusIsGood(response)) {
    throw new BadStatusError(response);
  }

  return response;
}
