import {
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { BRANDS_ROUTE, RESET_PASSWORD_URL } from '~/lib/constants';
import { BadStatusError, handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { responseStatusIsGood } from '~/lib/helpers';
import { EMAIL_RE } from '~/lib/regexp';
import accountState from '~/lib/state/account-state';
import { ResetPasswordSuccess } from './reset-password-success';

const CODE_PARAM = 'code';
const EMAIL_PARAM = 'email';

export const ResetPasswordModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const isLoggedIn = useIsLoggedIn();
  const { genericTranslation } = useTranslations();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { passwordValidation, confirmPasswordValidation } = useValidationSchema();
  const code = params.get(CODE_PARAM);
  const email = params.get(EMAIL_PARAM);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(genericTranslation.resetPassword.invalid)
      .test('email-valid', genericTranslation.resetPassword.invalid, (value) =>
        EMAIL_RE.test(value)
      )
      .email(),
    code: Yup.string().required(genericTranslation.resetPassword.invalid),
    newPassword: passwordValidation,
    confirmPassword: confirmPasswordValidation('newPassword'),
  });

  const onSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      try {
        const response = await fetchUrl(RESET_PASSWORD_URL, {
          method: 'POST',
          body: JSON.stringify({
            email: values.email,
            new_password: values.newPassword,
            verification_code: values.code,
          }),
          accountState,
        });

        if (!responseStatusIsGood(response)) {
          throw new BadStatusError(response);
        }

        setShowSuccess(true);
      } catch (error) {
        handleError(error, 'Unable to reset password');
      } finally {
        setSubmitting(false);
      }
    },
    [setSubmitting]
  );

  // ensure current user is signed out before proceeding with reset password flow
  useEffect(() => {
    if (isOpen && isLoggedIn) {
      accountState.do.signOut();
    }
  }, [isOpen, isLoggedIn]);

  const { handleSubmit, values, handleBlur, handleChange, errors } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues: {
      email,
      code,
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit,
  });

  const handleClose = useCallback(() => {
    onClose();

    // replace route with /brands
    const newParams = new URLSearchParams(window.location.search);
    newParams.delete(EMAIL_PARAM);
    newParams.delete(CODE_PARAM);
    navigate(`${BRANDS_ROUTE}?${newParams.toString()}`, { replace: true });
  }, [onClose, navigate]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered blockScrollOnMount>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {showSuccess ? (
            <ResetPasswordSuccess />
          ) : (
            <>
              <ModalHeader display='flex' alignItems='center' flexDirection='column' pt={0} mb={0}>
                <Text
                  as='h1'
                  fontSize='lg'
                  fontFamily='display'
                  fontWeight='medium'
                  color='neutral.primary'
                >
                  {genericTranslation.resetPassword.header}
                </Text>
              </ModalHeader>
              <ModalBody
                pt={0}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                gap={4}
              >
                <Text width='100%' color='secondary.primary' mb={4} textAlign='center'>
                  {genericTranslation.resetPassword.newSubHeader}
                </Text>
                <VStack as='form' width='100%' spacing={8} onSubmit={handleSubmit}>
                  <VStack width='100%' spacing={4}>
                    <VStack width='100%'>
                      <FormControl>
                        <InputGroup>
                          <Input
                            name='newPassword'
                            layerStyle='sign-in-field'
                            type={showNewPassword ? 'text' : 'password'}
                            value={values.newPassword}
                            placeholder={genericTranslation.resetPassword.newPlaceholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputRightElement height='100%'>
                            <IconButton
                              variant='unstyled'
                              onClick={() => setShowNewPassword((current) => !current)}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'blackAlpha.500',
                                mr: 1,
                                _hover: {
                                  backgroundColor: 'secondary.tertiary',
                                },
                              }}
                            >
                              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </IconButton>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <ErrorRow isPresent={Boolean(errors.newPassword)}>
                        {errors.newPassword}
                      </ErrorRow>

                      <FormControl mt={2}>
                        <InputGroup>
                          <Input
                            name='confirmPassword'
                            layerStyle='sign-in-field'
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={values.confirmPassword}
                            placeholder={genericTranslation.resetPassword.confirmNewPlaceholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputRightElement height='100%'>
                            <IconButton
                              variant='unstyled'
                              onClick={() => setShowConfirmPassword((current) => !current)}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'blackAlpha.500',
                                mr: 1,
                                _hover: {
                                  backgroundColor: 'secondary.tertiary',
                                },
                              }}
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </IconButton>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <ErrorRow isPresent={Boolean(errors.confirmPassword)}>
                        {errors.confirmPassword}
                      </ErrorRow>
                    </VStack>
                  </VStack>
                  <HStack width='100%'>
                    <Button
                      variant='outline'
                      colorScheme='secondary_scheme'
                      onClick={handleClose}
                      flexBasis='50%'
                    >
                      {genericTranslation.cancel}
                    </Button>
                    <Button
                      isDisabled={submitting}
                      type='submit'
                      onClick={handleSubmit}
                      flexBasis='50%'
                    >
                      {genericTranslation.resetPassword.reset}
                    </Button>
                  </HStack>
                </VStack>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
